import { useTranslation } from 'react-i18next';
import styles from './FormError.module.css';
import { FormError as FormErrorType } from './Form';

interface FormErrorProps {
    readonly errors?: FormErrorType[];
}

const FormError = ({ errors }: FormErrorProps) => {
    if (!errors || (errors && errors.length === 0)) {
        return null;
    }

    const { t } = useTranslation('validation');

    return (
        <div>
            {errors.map((error, index) => {
                return (
                    <div key={index} className={styles.FormError}>
                        {t(error.key, error.params)}
                    </div>
                );
            })}
        </div>
    );
}

export default FormError;
