import ImmutableModel from '../../../../models/immutableModel/immutableModel'
import {Moment} from 'moment'
import DiscardedLeadReasonEnum from './discardedLeadReasonEnum'

interface DiscardedLeadModelProps {
    readonly id: string
    readonly homeseekerName: string
    readonly homeseekerEmail: string
    readonly reason: DiscardedLeadReasonEnum
    readonly discardedAt: Moment
    readonly template: string
    readonly message: string
    readonly originalId: string|null
}

export default class DiscardedLeadModel extends ImmutableModel<DiscardedLeadModel, DiscardedLeadModelProps> {

    public getFormattedCreatedAt(): string {
        return this.value.discardedAt.format('dd, DD-MM-yyyy')
    }

    public getReasonResourceKey(): string {
        switch (this.value.reason) {
            case DiscardedLeadReasonEnum.AgentAssignerFailed:
                return 'discardedLeadsPage.reason.agentAssignerFailed'
            case DiscardedLeadReasonEnum.AnnotationStrategyFailed:
                return 'discardedLeadsPage.reason.annotationStrategyFailed'
            case DiscardedLeadReasonEnum.EnrichmentFailed:
                return 'discardedLeadsPage.reason.enrichmentFailed'
            default:
                return ''
        }
    }

    public getStrippedTemplate(): string {
        return this.value.template
            .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>|<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>|<\/?a\b[^>]*>?/gm, '')
            .replaceAll('\r\n', '<br>')
    }
}
