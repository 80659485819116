import { PageContainer } from '../../atoms/page-container';
import { ColorEnum } from '../../enums';
import { Main } from '../../organisms/main';
import { PageFooter } from '../../organisms/page-footer';
import { TopHeader } from '../../organisms/top-header';
import { Outlet } from 'react-router-dom';

const ScreeningPage = () => {
    return (
        <PageContainer
            backgroundColor={ColorEnum.Gray50}
        >
            <TopHeader
                hasTranslationSelector
            />
            <Main>
                <Outlet />
            </Main>
            <PageFooter/>
        </PageContainer>
    );
}

export default ScreeningPage;
