import { MouseEvent, ReactNode } from 'react';
import styles from './Label.module.css';
import { classNames } from '../../utils';

interface LabelProps {
    readonly className?: string;
    readonly htmlFor?: string;
    readonly children: ReactNode;
    readonly required?: boolean;
    readonly onClick?: (e: MouseEvent<HTMLLabelElement>) => void;
}

const Label = ({
    className,
    htmlFor,
    children,
    required,
    onClick
}: LabelProps) => {
    return (
        <label
            htmlFor={htmlFor}
            className={classNames(className && className, styles.label)}
            onClick={onClick}
        >
            {children}
            {required &&
                <span className={styles.required}>*</span>
            }
        </label>
    )
}

export default Label
