import React, { ReactNode, MouseEvent, ChangeEvent, FocusEvent, FormEvent, KeyboardEvent, useEffect, useRef } from 'react';
import styles from './InputField.module.css';
import { classNames } from '../../utils';
import { InputSize } from './InputSize';
import { FieldState } from '../../molecules/form/Form';

interface BaseInputFieldProps {
    readonly id?: string;
    readonly name?: string;
    readonly placeholder?: string;
    readonly value: string;
    readonly type?: string;
    readonly size?: InputSize;
    readonly iconBefore?: ReactNode;
    readonly iconAfter?: ReactNode;
    readonly required?: boolean;
    readonly maxlength?: number;
    readonly minlength?: number;
    readonly pattern?: string;
    disabled?: boolean;
    autofocus?: boolean;
    readonly onClick?: (e: MouseEvent) => void;
    readonly onFocus?: (e: FocusEvent) => void;
    readonly onBlur?: (e: FocusEvent) => void;
    readonly onInput?: (e: FormEvent) => void;
    readonly onKeyDown?: (e: KeyboardEvent) => void;
}

interface DefaultInputFieldProps extends BaseInputFieldProps {
    readonly isSearch?: false;
    readonly onChange?: (state: FieldState) => void;
}

interface SearchInputFieldProps extends BaseInputFieldProps {
    readonly isSearch: true;
    readonly onChange: (state: FieldState, matchSearch?: (str1: string, str2: string) => void) => void;
}

type InputFieldProps = DefaultInputFieldProps | SearchInputFieldProps;

const InputField = ({
    id,
    name,
    placeholder,
    value = '',
    type = 'text',
    maxlength,
    minlength,
    pattern,
    size = 'lg',
    iconBefore,
    iconAfter,
    disabled,
    required,
    isSearch,
    autofocus = false,
    onClick,
    onChange,
    onFocus,
    onBlur,
    onInput,
    onKeyDown,
}: InputFieldProps) => {
    const input = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (input.current && autofocus) {
            input.current.focus();
        }
    }, [input])

    const handleClick = (e: MouseEvent) => {
        e.stopPropagation();
        onClick?.(e);
    }

    const handleInputClick = (e: MouseEvent) => {
        e.stopPropagation();
        onClick?.(e);
    }

    const matchSearch = (str1: string , str2: string) => {
        const normalizedString1 = str1
            .replaceAll(' ', '')
            .trim()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
        const normalizedString2 = str2
            .replaceAll(' ', '')
            .trim()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')

        return normalizedString1.includes(normalizedString2);
    }

    const createFieldState = (target: HTMLInputElement): FieldState => {
        return {
            name: name,
            value: target.value,
        };
    }

    const handleChange = (e: ChangeEvent) => {
        if (!onChange) {
            return;
        }

        if (isSearch === true) {
            onChange(createFieldState(e.target as HTMLInputElement), matchSearch);
        } else {
            onChange(createFieldState(e.target as HTMLInputElement));
        }
    }

    return (
        <>
            <div
                onClick={handleClick}
                className={classNames(styles.InputField, iconBefore && styles.hasIconBefore, iconAfter && styles.hasIconAfter, styles[size])}
            >
                {iconBefore &&
                    <span className={classNames(styles.iconBefore)}>
                        {iconBefore}
                    </span>
                }
                <input
                    ref={input}
                    id={id}
                    name={name}
                    value={value}
                    type={type}
                    maxLength={maxlength}
                    minLength={minlength}
                    pattern={pattern}
                    onChange={handleChange}
                    placeholder={placeholder}
                    onClick={handleInputClick}
                    disabled={disabled}
                    required={required}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onInput={onInput}
                    onKeyDown={onKeyDown}
                />
                {iconAfter &&
                    <span className={classNames(styles.iconAfter)}>
                        {iconAfter}
                    </span>
                }
            </div>
        </>
    );
};

export default InputField;
