import { forwardRef, useRef, useImperativeHandle, useEffect, useLayoutEffect, useState } from 'react';
import styles from './TextArea.module.css';
import Quill from 'quill';
import QuillResizeImage from 'quill-resize-image';
import ImageCompress from 'quill-image-compress';
import classNames from '../../utils/classNames';
import VariablesDropdownBlot from './utils/VariableDropdownBlot';
import { replaceVariables } from './utils/Variables';
import Toolbar from './Toolbar';
import HeadingBlot from './utils/HeadingBlot';
import replaceOlUlTags from './utils/replaceOlUlTags';
import LinkTooltip from './LinkTooltip';
import LinkBlot from './utils/LinkBlot';

Quill.register('formats/custom-link', LinkBlot);
Quill.register('formats/custom-header', HeadingBlot);
Quill.register('formats/variables-dropdown', VariablesDropdownBlot);
Quill.register('modules/resize', QuillResizeImage);
Quill.register('modules/imageCompress', ImageCompress);

window.QuillResizeImage = QuillResizeImage;

interface TextAreaProps {
    readonly className?: string;
    readonly name: string;
    readonly value: string;
    readonly onChange: (name: string, value: string) => void;
    readonly toolbarExtensions?: Array<{label: string, options: string[]}>;
    readonly allowImages?: boolean;
    readonly disabled?: boolean;
    readonly hidden?: boolean;
}

const TextArea = forwardRef<Quill, TextAreaProps>(
    (
        {
            className = 'defaultQuillEditor',
            name,
            value,
            onChange,
            toolbarExtensions = [],
            allowImages = false,
            disabled = false,
            hidden = false
        },
        forwardedRef?
    ) => {
        const ref = useRef<Quill>(null);

        const [quill, setQuill] = useState<Quill | null>(null);
        const [isTooltipVisible, setIsTooltipVisible] = useState(false);
        const [variables, ] = useState(toolbarExtensions?.[0]?.options);

        useImperativeHandle(forwardedRef, () => ref.current as Quill);

        const onChangeRef = useRef(onChange);
        const containerRef = useRef<HTMLDivElement | null>(null);
        const valueRef = useRef(null);


        useEffect(() => {
            if (variables) {
                value = replaceVariables(value, variables);
            }
        }, []);

        useEffect(() => {
            const formats = [
                'custom-header',
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'color', 'list', 'indent',
                'custom-link'
            ];
            const toolbarImportModules = [];
            let image = '';
            const toolbarExtensionModules = [];

            if (allowImages == true) {
                image = 'image';
                toolbarImportModules.push(image);
            }

            if (toolbarExtensions.length > 0) {
                for (let i = 0; i < toolbarExtensions.length; i++) {
                    const extension = toolbarExtensions[i];
                    toolbarExtensionModules[extension.label] = extension.options
                    formats.push(extension.label);
                }
            }

            const modules: Record<string, Array<object | string> | boolean | object> = {
                toolbar: {
                    container: [
                        toolbarImportModules,
                        toolbarExtensionModules
                    ]
                },
                imageCompress: {
                    maxWidth: 600
                },
                resize: {
                    locale: {},
                }
            };

            if (allowImages) {
                formats.push('image');
            }

            const container = containerRef.current;
            const quillContainer = container.appendChild(
                container.ownerDocument.createElement('article'),
            );

            quillContainer.id = name;
            const q = new Quill(quillContainer, {
                formats,
                modules,
                theme: 'snow',
            });

            setQuill(q);

            ref.current = q;

            return () => {
                container.innerHTML = '';
                ref.current = null;
            };
        }, [containerRef]);

        useEffect(() => {
            if (!quill) {
                return;
            }

            quill.on(Quill.events.TEXT_CHANGE, handleChange);

            return () => {
                quill.off(Quill.events.TEXT_CHANGE, handleChange);
            };
        }, [quill]);

        useEffect(() => {
            ref.current?.enable(!disabled);
        }, [disabled]);

        useEffect(() => {
            if (variables) {
                value = replaceVariables(value, variables);
            }

            valueRef.current = value;

            const quillRef = ref.current;

            if (quillRef && (isDifferentIgnoreUlOl(quillRef.root.innerHTML, valueRef.current))) {
                const range = quillRef.getSelection();
                let length = 0;
                if (range) {
                    length = quillRef.getContents()?.length();
                }

                quillRef.setContents(quillRef.clipboard.convert({html: valueRef.current}));

                length -= quillRef.getContents()?.length();

                if (range && length > 0) {
                    quillRef.setSelection(range.index - length);
                }
            }
        }, [value]);

        useLayoutEffect(() => {
            onChangeRef.current = onChange;
        });

        const isDifferentIgnoreUlOl = (aText: string, bText: string): boolean => {
            const aTextRemovedUlOl = aText.replaceAll('<ul>', '').replaceAll('<ol>', '').replaceAll('</ul>', '').replaceAll('</ol>', '');
            const bTextRemovedUlOl = bText.replaceAll('<ul>', '').replaceAll('<ol>', '').replaceAll('</ul>', '').replaceAll('</ol>', '');

            return aTextRemovedUlOl !== bTextRemovedUlOl;
        }

        const handleChange = () => {
            let quillContent = ref.current?.root.innerHTML;

            if (isDifferentIgnoreUlOl(ref.current?.root.innerHTML, valueRef.current)) {
                quillContent = replaceOlUlTags(quillContent);
                onChange(name, quillContent);
            }
        }

        const handleLinkClick = () => {
            setIsTooltipVisible(true);
        }

        const handleTooltipOpen = () => {
            setIsTooltipVisible(true);
        }

        const handleTooltipClose = () => {
            setIsTooltipVisible(false);
        }

        return (
            <div className={styles.TextAreaContainer}>
                {(!hidden && quill) &&
                    <Toolbar
                        quill={quill}
                        variables={variables}
                        image={allowImages}
                        onLinkClick={handleLinkClick}
                        isLinkTooltipVisible={isTooltipVisible}
                    />
                }
                <div
                    ref={containerRef}
                    id={`${name}Container`}
                    className={classNames(styles.Textarea, className && className)}
                    hidden={hidden}
                >
                    <LinkTooltip
                        quill={quill}
                        onTooltipOpen={handleTooltipOpen}
                        onTooltipClose={handleTooltipClose}
                        isVisible={isTooltipVisible}
                    />
                </div>
            </div>
        );
    },
);

TextArea.displayName = 'TextArea';

export default TextArea;
