import {SET_NOTIFICATION} from './types';
import store from '../../store';

export const TYPES = {
    SUCCESS: 'success',
    FAILED: 'failed',
};

export const setNotification = (data) => (dispatch) => {
    return dispatch({
        type: SET_NOTIFICATION,
        payload: data,
    });
};

export const setNewNotification = (data) => {
    return store.dispatch({
        type: SET_NOTIFICATION,
        payload: data,
    });
}
