import React, { useState, useEffect } from 'react';
import styles from './PetSelector.module.css';
import { Select } from '../select';
import IncrementalNumberInput from '../../molecules/incremental-number-input/IncrementalNumberInput';
import ButtonNew from '../../molecules/button/Button';
import DeleteBinIcon from '../../atoms/icons/DeleteBinIcon';
import InputField from '../input-field/InputField';
import Label from '../../atoms/label/Label';
import { classNames } from '../../utils';
import { useTranslation } from 'react-i18next';

interface SelectWithCounterProps {
    readonly index: number;
    readonly value: string;
    readonly amount: number;
    readonly label?: string;
    readonly inputFieldPlaceholder?: string;
    readonly maxLengthInputFieldValue?: number;
    readonly minIncrementalNumberInputValue?: number;
    readonly maxIncrementalNumberInputValue?: number;
    readonly onChange: (index: number, species?: string, amount?: number) => void;
    readonly isDeletable?: boolean;
    readonly onDelete?: () => void;
}

const PetSelector = ({
    index,
    value,
    amount,
    label,
    inputFieldPlaceholder,
    maxLengthInputFieldValue,
    minIncrementalNumberInputValue,
    maxIncrementalNumberInputValue,
    onChange,
    isDeletable = false,
    onDelete
}: SelectWithCounterProps) => {
    const { t } = useTranslation();

    const options = [
        {
            value: 'cat',
            label: t('screening.household.listItem.cat'),
        },
        {
            value: 'dog',
            label: t('screening.household.listItem.dog'),
        },
        {
            value: 'fish',
            label: t('screening.household.listItem.fish'),
        },
        {
            value: 'rabbit',
            label: t('screening.household.listItem.rabbit'),
        },
        {
            value: 'other',
            label: t('screening.household.listItem.other'),
        }
    ];

    const isValueInOptions = (checkValue: string): boolean => {
        return options.some(option => option.value === checkValue && checkValue !== 'other');
    }

    const [isTextInputVisible, setIsTextInputVisible] = useState(!isValueInOptions(value));

    useEffect(() => {
        setIsTextInputVisible(!isValueInOptions(value));
    });

    const handleSelectChange = (newValue: string) => {
        if (!isValueInOptions(newValue)) {
            setIsTextInputVisible(true);
            onChange(index, newValue, undefined);
        } else {
            setIsTextInputVisible(false);
            onChange(index, newValue, undefined);
        }
    };

    const handleInputChange = (newValue: string) => {
        onChange(index, newValue, undefined);
    };

    const handleAmountChange = (newAmount: number) => {
        onChange(index, undefined, newAmount);
    };

    const deleteRow = () => {
        if (onDelete) {
            onDelete();
        }
    };

    return (
        <div className={!isTextInputVisible ? styles.container : styles.containerAlt}>
            <div className={styles.selectContainer}>
                <Select
                    items={options}
                    selected={(isValueInOptions(value)) ? value : 'other'}
                    onChange={(item) => handleSelectChange(item.value)}
                    fullWidth={true}
                />
            </div>
            {isTextInputVisible &&
                <>
                    {label &&
                        <div className={styles.labelContainer}>
                            <Label>{label}</Label>
                        </div>
                    }
                    <div className={styles.inputContainer}>
                        <InputField
                            value={(value !== 'other') ? value : ''}
                            onChange={(fieldState) => handleInputChange(fieldState.value)}
                            maxlength={maxLengthInputFieldValue}
                            pattern="[a-zA-Z\s]"
                            autofocus
                            placeholder={inputFieldPlaceholder}
                        />
                    </div>
                </>
            }
            <div
                className={
                    classNames(
                        !isTextInputVisible ? styles.buttonContainer : styles.buttonContainerAlt,
                        !isDeletable && styles.singleColumn
                    )}
            >
                <IncrementalNumberInput
                    min={minIncrementalNumberInputValue}
                    max={maxIncrementalNumberInputValue}
                    value={amount}
                    onChange={(newAmount: number) => handleAmountChange(newAmount)}
                />
                {isDeletable &&
                    <ButtonNew
                        variant="tetriary-gray"
                        iconBefore={<DeleteBinIcon/>}
                        onClick={deleteRow}
                    />
                }
            </div>
        </div>
    );
};

export default PetSelector;
