import { FormEvent, ReactNode, createContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface Data {
    [key: string]: {
        value: string;
        isValid: boolean
    }
}

export interface FieldState {
    name: string;
    value: string;
}

export interface FormError {
    key: string;
    params?: {[key: string]: string | number};
}
export type FormErrors = {[key: string]: FormError[]};

interface FormFieldObject {
    element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;
    value: string;
    isValid: boolean;
    errors: FormError[];
}

interface FormContextType {
    errors: FormErrors;
    formData: Data;
    isFormValid: boolean;
}

interface FromProps {
    children: ReactNode;
    method?: 'dialog' | 'get' | 'post' | 'DIALOG' | 'GET' | 'POST';
    action?: string;
    target?: '_blank' | '_self' | '_parent' | '_top';
    enctype?: 'application/x-www-form-urlencoded' | 'multipart/form-data' | 'text/plain';
    onChange?: (data: Data, isValid: boolean, errors: FormErrors) => void;
    onSubmit?: () => void;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

const Form = ({
    children,
    method,
    action,
    target,
    enctype,
    onChange,
    onSubmit,
}: FromProps) => {
    const formRef = useRef<HTMLFormElement>(null);
    const formDataRef = useRef<Data>({});

    const [ formFieldObjects, setFormFieldObjects ] = useState<{[key: string]: FormFieldObject} | []>([]);
    const [ formData, setFormData ] = useState<Data>({});
    const [ isFormValid, setIsFormValid ] = useState<boolean>(false);
    const [ errors, setErrors ] = useState<FormErrors>({});

    const { t } = useTranslation('validation');

    useEffect(() => {
        if (onChange) {
            onChange(formData, isFormValid, errors);
        }

        setIsFormValid(Object.values(formData).every((field) => field.isValid));
    }, [formData, isFormValid, errors, onChange]);

    useEffect(() => {
        const updatedErrors = {};

        for (const [key, field] of Object.entries(formData)) {
            if (!formDataRef.current[key]?.isValid && field.isValid === true) {
                let element = formRef.current.elements[key] as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | RadioNodeList;

                if (element instanceof RadioNodeList) {
                    element = Array.from(element).find((input) => (input as HTMLInputElement).checked) as HTMLInputElement | undefined;
                }

                updatedErrors[key] = getValidationTranslationKeys(element);
            }
        }

        setErrors((prev) => {
            return {
                ...prev,
                ...updatedErrors
            };
        });
    }, [formData]);

    const getNamedValidationTranslationKey = (key: string, nameKey: string): string => {
        if (t(`${key}${nameKey.replace(/\*$/, '')}`, { defaultValue: null })) {
            return `${key}${nameKey.replace(/\*$/, '')}`;
        } else if (t(`${key}${nameKey}`, { defaultValue: null })) {
            return `${key}${nameKey}`;
        } else {
            return key;
        }
    }

    const getValidationTranslationKeys = (element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement): FormError[] => {
        const validity: ValidityState = element.validity;
        const validationTranslationKeys: FormError[] = [];

        const name: string = element.name;
        let nameKey = '';

        if (name !== '') {
            nameKey = `.${name.replace(/-\d+$/, '*')}`;
        }

        if (validity.valueMissing) {
            validationTranslationKeys.push({
                key: getNamedValidationTranslationKey('required', nameKey),
            });
        }

        if (element.type && validity.typeMismatch) {
            validationTranslationKeys.push({
                key: getNamedValidationTranslationKey(`typeMismatch.${element.type.toLowerCase()}`, nameKey),
            });
        }

        if ('minLength' in element && validity.tooShort) {
            validationTranslationKeys.push({
                key: getNamedValidationTranslationKey('tooShort', nameKey),
                params: {
                    minLength: element.minLength
                },
            });
        }

        if ('maxLength' in element && validity.tooLong) {
            validationTranslationKeys.push({
                key: getNamedValidationTranslationKey('tooLong', nameKey),
                params: {
                    maxLength: element.maxLength
                },
            });
        }

        if ('min' in element && validity.rangeUnderflow) {
            validationTranslationKeys.push({
                key: getNamedValidationTranslationKey('rangeUnderflow', nameKey),
                params: {
                    min: element.min
                },
            });
        }

        if ('max' in element && validity.rangeOverflow) {
            validationTranslationKeys.push({
                key: getNamedValidationTranslationKey('rangeOverflow', nameKey),
                params: {
                    max: element.max
                },
            });
        }

        if ('pattern' in element && validity.patternMismatch) {
            validationTranslationKeys.push({
                key: getNamedValidationTranslationKey('patternMismatch', nameKey),
            });
        }

        return validationTranslationKeys;
    }

    const updateFormFieldObjects = () => {
        const form = formRef.current;

        if (!form) {
            return [];
        }

        const updatedFormFieldObjects: {[key: string]: FormFieldObject} = {};

        for (const element of formRef.current.elements) {
            if (!(element instanceof HTMLInputElement || element instanceof HTMLSelectElement || element instanceof HTMLTextAreaElement)) {
                continue;
            }

            if (element.name === '') {
                continue;
            }

            let value = element.value;

            if (element instanceof HTMLInputElement && (element.type === 'radio' || element.type === 'checkbox') && !element.checked) {
                value = '';
            }

            if (updatedFormFieldObjects[element.name] === undefined || (element instanceof HTMLInputElement && (element.type === 'radio' || element.type === 'checkbox') && element.checked)) {
                updatedFormFieldObjects[element.name] = {
                    value,
                    element,
                    isValid: element.checkValidity(),
                    errors: getValidationTranslationKeys(element)
                };
            }
        }

        setFormFieldObjects(updatedFormFieldObjects);
    }

    const handleInputChange = () => {
        updateFormFieldObjects();
    }

    useEffect(() => {
        const form = formRef.current;

        if (form) {
            form.addEventListener('input', handleInputChange);
            form.addEventListener('change', handleInputChange);
        }

        updateFormFieldObjects();

        return () => {
            if (form) {
                form.removeEventListener('input', handleInputChange);
                form.removeEventListener('change', handleInputChange);
            }
        };
    }, [formRef.current]);

    useEffect(() => {
        const updatedFormData = {};

        for (const name in formFieldObjects) {
            const formFieldObject = formFieldObjects[name];

            updatedFormData[name] = {
                value: formFieldObject.value,
                isValid: formFieldObject.element.checkValidity()
            };
        }

        setFormData(updatedFormData);
    }, [formFieldObjects])

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const updatedErrors: FormErrors = {};

        for (const name in formFieldObjects) {
            const formFieldObject = formFieldObjects[name];

            updatedErrors[name] = formFieldObject.errors;
        }

        setErrors(updatedErrors);

        if (isFormValid) {
            if (action) {
                formRef.current.submit();
            }

            onSubmit?.();
        }
    }

    return (
        <FormContext.Provider value={{ errors, formData, isFormValid }}>
            <form
                ref={formRef}
                noValidate
                method={method}
                action={action}
                target={target}
                encType={enctype}
                onSubmit={handleSubmit}
            >{children}</form>
        </FormContext.Provider>
    );
};


export default Form;
