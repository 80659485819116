interface ScreeningSteps {
    [key: string]: {
        submitTranslationKey: string
    }
}

const screeningSteps: ScreeningSteps = {
    'ScreeningIntroduction': {
        submitTranslationKey: 'screening.introduction.start.verification'
    },
    'ScreeningHousehold' : {
        submitTranslationKey: 'screening.household.submit.button.text'
    },
    'ScreeningCoTenants': {
        submitTranslationKey: 'screening.coTenants.submit.button.next'
    }
};

export default screeningSteps;
