import { useState, ReactNode, useEffect, ChangeEvent, MouseEvent } from 'react';
import { Badge } from '../../molecules/badge';
import Select, { findItemByValue, Item } from '../select/Select';
import CloseIcon from '../../atoms/icons/CloseIcon';
import findHomeseekerApplicationsForSelector, { HomeseekerApplicationForSelector } from '../../../api/homeseeker-application/findHomeseekerApplicationsForSelector';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../redux/actions/ui/setNotification';
import styles from './HomeseekerSelector.module.css';
import selectStyles from '../select/Select.module.css';
import ButtonNew from '../../molecules/button/Button';
import Label from '../../atoms/label/Label';

interface HomeseekerSelectorProps {
    transactionalListingId: string;
    preselectedHomeseekers?: HomeseekerApplicationForSelector[];
    onChange?: (e: HomeseekerApplicationForSelector[]) => void
}

const HomeseekerSelector = ({
    transactionalListingId,
    preselectedHomeseekers = [],
    onChange
}: HomeseekerSelectorProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [selectedItems, setSelectedItems] = useState<HomeseekerApplicationForSelector[]>(preselectedHomeseekers);
    const [items, setItems] = useState<HomeseekerApplicationForSelector[]>(preselectedHomeseekers);
    const [isLoading, setIsLoading] = useState(false);
    const [collapsedBadges, setCollapsedBadges] = useState(true);

    const maxCollapsedBadges = 5;

    useEffect(() => {
        fetchHomeseekerApplications();
    }, []);

    useEffect(() => {
        onChange?.(selectedItems);
    }, [selectedItems]);

    const HomeseekerApplicationForSelectorArrayToItemArray = (homeseekers: HomeseekerApplicationForSelector[]): Item[] => {
        return homeseekers.map((homeseeker) => {
            return {
                label: homeseeker.fullName,
                value: homeseeker.id,
            }
        })
    }

    const showCollapsableOptions = () => {
        return selectedItems.length > maxCollapsedBadges + 1;
    }

    const showMaxSelected = () => {
        return (collapsedBadges && showCollapsableOptions()) ? maxCollapsedBadges : selectedItems.length;
    }

    const fetchHomeseekerApplications = async (inputValue = '') => {
        setIsLoading(true);

        const searchValue = inputValue.trim();
        try {
            const response = await findHomeseekerApplicationsForSelector(transactionalListingId, searchValue);

            setItems([
                ...selectedItems,
                ...response.filter(item => !selectedItems.find(findItem => findItem.id === item.id))
            ]);
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsLoading(false);
        }
    }

    const toggleCollapsedBadges = () => {
        setCollapsedBadges(!collapsedBadges)
    }

    const handleSelectOnChange = (item: Item) => {
        if (selectedItems.find((selectedItem) => selectedItem.id === item.value)) {
            // remove
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.value));
        } else {
            // add
            const foundItem = findItemByValue(HomeseekerApplicationForSelectorArrayToItemArray(items), item.value);

            if (foundItem === undefined) {
                return;
            }

            setSelectedItems([...selectedItems, {
                id: foundItem.value,
                fullName: (foundItem.label as string),
            }])
        }
    }

    const handleBadgeCloseIconClick = (e: MouseEvent, item: Item) => {
        e.stopPropagation();
        setSelectedItems(selectedItems.filter(selectedItem => selectedItem.id !== item.value))
    }

    const renderBadge = (item: Item, index: number) => {
        return (
            <Badge
                key={index}
                label={(item.label as string)}
                after={
                    <div
                        onClick={(e) => handleBadgeCloseIconClick(e, item)}
                    >
                        <CloseIcon className={styles.BadgeCloseIcon}/>
                    </div>
                }
            />
        );
    }

    const handleCollapseClick = (e: MouseEvent) => {
        e.stopPropagation();
        toggleCollapsedBadges();
    }

    const renderSelected = (selected: string[]): ReactNode => {
        return (
            <>
                {selected.slice(0, showMaxSelected()).map((val, index) => {
                    const item = findItemByValue(HomeseekerApplicationForSelectorArrayToItemArray(items), val);

                    if (item === undefined) {
                        return;
                    }

                    return renderBadge(item, index);
                })}
                {showCollapsableOptions() &&
                    <>
                        {collapsedBadges &&
                            <div onClick={handleCollapseClick} className={styles.CollapseSelectedCounter}>
                                <Badge label={`+${selected.length - maxCollapsedBadges}`}/>
                            </div>
                        }
                        <ButtonNew
                            size="md"
                            inline
                            variant='link-underline'
                            onClick={handleCollapseClick}
                        >{collapsedBadges ? t('homeseeker.selector.view.more') : t('homeseeker.selector.view.less')}</ButtonNew>
                    </>
                }
            </>
        );
    }

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        fetchHomeseekerApplications(val);
    }

    const selectAllItems = (): void => {
        setSelectedItems(items);
    }

    const deselectAllItems = (): void => {
        setSelectedItems([]);
    }

    const renderDropdownHeader = (): ReactNode => {
        return (
            <div className={styles.DropdownHeader}>
                <ButtonNew
                    size="sm"
                    variant='link'
                    onClick={selectAllItems}
                    disabled={selectedItems.length !== 0 && selectedItems.length === items.length}
                >{t('general.select.all')}</ButtonNew>
            </div>
        );
    }

    const renderCustomLabel = (): ReactNode => {
        return (
            <div className={styles.Label}>
                <Label className={styles.labelText}>
                    <Trans
                        i18nKey="homeseeker.selector.label"
                        values={{ number: selectedItems.length }}
                        components={{ strong: <strong /> }}
                    />
                </Label>
                <ButtonNew
                    size="sm"
                    variant='link'
                    onClick={deselectAllItems}
                    disabled={selectedItems.length === 0}
                >{t('general.deselect.all')}</ButtonNew>
            </div>
        );
    }

    return (
        <>
            <Select
                className={selectStyles.HomeseekerSelector}
                fullWidth
                renderCustomLabel={renderCustomLabel}
                hasInputField
                multiple
                subText={t('candidate.action.invite.viewing.homeseekerSelector.subText')}
                isLoadingItems={isLoading}
                placeholder={t('homeseeker.selector.placeholder')}
                selected={selectedItems.map(item => item.id )}
                items={HomeseekerApplicationForSelectorArrayToItemArray(items)}
                renderCustomSelected={renderSelected}
                onChange={handleSelectOnChange}
                onInputChange={handleInputChange}
                renderDropdownHeader={renderDropdownHeader}
            />
        </>
    );
};

export default HomeseekerSelector;
