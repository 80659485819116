import api from '../../../utils/api';
import {setNotification} from '../ui/setNotification';
import store from '../../store';

export const requestExtraInformation = (ids, listingId) => {
    store.dispatch({type: 'START_LOADING'});

    return api.post('api/agency/homeseeker-applications/send-enrichment-form',
        {
            transactionalListingId: listingId,
            homeseekerApplicationIds: ids
        }
    ).then(response => {
        if (response.status >= 200 && response.status < 300) {
            store.dispatch({type: 'STOP_LOADING'});
            store.dispatch(setNotification({
                id: Date.now(),
                message: ids.length > 1 ? 'Informatieverzoeken opgestuurd.' : 'Informatieverzoek opgestuurd.',
                type: 'success'
            }));
        }
    }).catch((e) => {
        store.dispatch({type: 'STOP_LOADING'});
        console.log(e);
    }).finally(() => store.dispatch({type: 'STOP_LOADING'}));
};
