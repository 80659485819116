import ModalEventManager, { ModalEventData } from './ModalEventManager';

export interface CreateNoteModalEventData extends ModalEventData {
    homeseekerApplicationId?: string;
}

export type CreateNoteModalCustomEventType = CustomEvent<CreateNoteModalEventData>;

class CreateNoteModalEventManager<CreateNoteModalEventData> extends ModalEventManager<CreateNoteModalEventData> {
    public readonly homeseekerApplicationId?: string;

    protected constructor(event_name: string) {
        super(event_name);

        this.homeseekerApplicationId = null;
    }

    public static create = <CreateNoteModalEventData>(event_name: string): CreateNoteModalEventManager<CreateNoteModalEventData> => {
        return new CreateNoteModalEventManager(event_name);
    }

    public setOpen = (open: boolean, homeseekerApplicationId?: string): void => {
        this.dispatch({ open, homeseekerApplicationId } as CreateNoteModalEventData);
    }

    public open = (homeseekerApplicationId?: string): void => {
        this.setOpen(true, homeseekerApplicationId);
    }

    public close = (homeseekerApplicationId?: string): void => {
        this.setOpen(false, homeseekerApplicationId);
    }

    public toggle = (homeseekerApplicationId?: string): void => {
        this.setOpen(!this.isOpen, homeseekerApplicationId);
    }
}

export default CreateNoteModalEventManager;
