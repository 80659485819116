import Quill, { Range } from 'quill';
import { classNames } from '../../utils';
import styles from './TextAreaTooltip.module.css';
import { useEffect, useState } from 'react';
import { InputField } from '../../organisms/input-field';
import ButtonNew from '../../molecules/button/Button';
import DeleteBin7Icon from '../icons/DeleteBin7Icon';
import { useTranslation } from 'react-i18next';
import ExternalLinkLineIcon from '../icons/ExternalLinkLineIcon';
import { FieldState } from '../../molecules/form/Form';

interface LinkTooltip {
    quill: Quill | null;
    onTooltipOpen: () => void;
    onTooltipClose: () => void;
    isVisible: boolean;
}

const LinkTooltip = ({
    quill,
    onTooltipOpen,
    onTooltipClose,
    isVisible,
}: LinkTooltip) => {
    const { t } = useTranslation();

    const [linkValue, setLinkValue] = useState('');
    const [linkRange, setLinkRange] = useState<Range | null>(null);
    const [editMode, setEditMode] = useState(false);
    const [position, setPosition] = useState<{top: number, left: number}>({top: 0, left: 0});

    useEffect(() => {
        if (!quill) {
            return;
        }

        quill.on(Quill.events.SELECTION_CHANGE, selectionChange);

        return () => {
            quill.off(Quill.events.SELECTION_CHANGE, selectionChange);
        };
    }, [quill]);

    useEffect(() => {
        if (quill) {
            if (isVisible) {
                setEditMode(false);

                const updateLinkInfoSuccess = updateLinkInfo(quill.getSelection().index);

                if (!updateLinkInfoSuccess) {
                    setLinkRange(quill.getSelection())
                }
            } else {
                setEditMode(true);
            }
        }
    }, [isVisible]);

    useEffect(() => {
        updatePosition(linkRange);
    }, [linkRange]);

    const close = () => {
        setLinkRange(null);
        setLinkValue('');
        setEditMode(false);
        onTooltipClose();
    }

    const updatePosition = (range: Range | null) => {
        if (range === null) {
            return;
        }

        const bounds = quill.getBounds(range.index);
        setPosition({ top: bounds.bottom + 8, left: bounds.left });
    }

    const selectionChange = () => {
        const range = quill.selection.savedRange;

        if (!range) {
            return;
        }

        updateLinkInfo(quill.selection.lastRange?.index);

        const formats = quill.getFormat(range);

        if ('custom-link' in formats) {
            onTooltipOpen();
        } else if (quill.selection.lastRange !== null) {
            close();
        }
    }

    const handleInputChange = (state: FieldState) => {
        setLinkValue(state.value);
    };

    const onApplyClick = () => {
        quill.formatText(linkRange, 'custom-link', linkValue);
        close();
    }

    const onEditClick = () => {
        setEditMode(true);
    }

    const updateLinkInfo = (index: number | undefined): boolean => {
        if (index === undefined) {
            return false;
        }

        if (quill.selection.savedRange.length > 0) {
            index++;
        }

        const [leaf, ] = quill.getLeaf(index);
        const blot = leaf.parent;

        if (blot.domNode.tagName === 'A') {
            const index = blot.offset(quill.scroll);
            const length = blot.length();

            const range: Range = new Range(index, length);
            setLinkRange(range);
            setLinkValue(blot.domNode.getAttribute('href'));

            setEditMode(false);

            return true;
        } else {
            setEditMode(true);
            return false;
        }
    };

    const onRemoveClick = () => {
        quill.formatText(linkRange, 'custom-link', false);

        close();
    }

    const handleKeyDown = (e) => {
        if (isVisible && editMode && e.key === 'Enter') {
            e.preventDefault();
            onApplyClick();
        }
    }

    return (
        <div
            className={classNames(styles.LinkTooltip, isVisible && styles.isVisible)}
            style={{ top: position.top, left: position.left }}
        >
            {!editMode ? (
                <>
                    <div className={styles.linkContainer}>
                        <ButtonNew
                            className={styles.linkButton}
                            iconBefore={<ExternalLinkLineIcon/>}
                            size={'sm'}
                            variant={'link'}
                            onClick={() => window.open(linkValue)}
                        >
                            <div className={styles.linkValue}>{linkValue}</div>
                        </ButtonNew>
                    </div>
                    <ButtonNew
                        size={'sm'}
                        variant={'secondary-gray'}
                        onClick={onEditClick}
                    >{ t('edit')}</ButtonNew>
                    <ButtonNew
                        size={'sm'}
                        variant={'secondary-gray'}
                        onClick={onRemoveClick}
                        iconBefore={<DeleteBin7Icon/>}
                    ></ButtonNew>
                </>
            ) : (
                <>
                    <InputField
                        size={'sm'}
                        value={linkValue}
                        onChange={handleInputChange}
                        placeholder={'URL'}
                        onKeyDown={handleKeyDown}
                        autofocus={editMode && isVisible}
                    />
                    <ButtonNew
                        size={'sm'}
                        variant={'secondary-gray'}
                        onClick={onApplyClick}
                        type="submit"
                    >{ t('apply')}</ButtonNew>
                </>
            )}
        </div>
    );
}

export default LinkTooltip;
