import React from 'react';
import styles from './Icon.module.css';
import { classNames } from '../../utils';

export interface IconProps {
    className?: string;
    selectedColor?: false | string;
}

const FontColorIcon = ({
    className,
    selectedColor
}: IconProps) => {
    return (
        <div className={classNames(styles.icon, className ?? className)}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
            >
                <path d="M15.2459 14H8.75407L7.15407 18H5L11 3H13L19 18H16.8459L15.2459 14ZM14.4459 12L12 5.88516L9.55407 12H14.4459Z"></path>
                <path
                    fill={selectedColor ? selectedColor : '#000000'}
                    d="M3 20H21V22H3V20Z"
                ></path>
            </svg>
        </div>
    );
};

export default FontColorIcon;
