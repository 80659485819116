import SkeletonComponent from '../../../../components/SkeletonComponent';
import { Switch } from '@headlessui/react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ListingProperties } from '../../../../components/listings/listingProperties/ListingProperties';
import ListingPropertiesModel
    from '../../../../components/listings/listingProperties/logics/model/listingPropertiesModel';
import styles from './ListingDetailsHeader.module.css';
import AddressModel from '../../../../models/listing/addressModel';
import ListingStatusTimerModel from '../../../../models/listing/listingStatusTimerModel';
import Badge_Deprecated from '../../../../components/generics/badge_deprecated/Badge_Deprecated';
import Tooltip from '../../../../components/tooltip/Tooltip';
import listingStatus from '../../../../enums/listingStatus';
import Text from '../../../../components/typography/text/Text';
import colorEnum from '../../../../enums/colorEnum';
import fontSizeEnum from '../../../../components/typography/enums/fontSizeEnum';
import fontWeightEnum from '../../../../components/typography/enums/fontWeightEnum';
import maxWidthEnum from '../../../../enums/maxWidthEnum';
import Button from '../../../../components/buttons/button/Button';
import ButtonType from '../../../../components/buttons/button/logic/models/buttonType';
import ButtonSize from '../../../../components/buttons/button/logic/models/buttonSize';
import IconType from '../../../../components/buttons/button/logic/models/iconType';
import HighlightBlock from '../../../../componentsNew/molecules/highlightBlock/HighlightBlock';

export interface ListingDetailsHeaderProps {
    readonly addressModel: AddressModel;
    readonly listingProperties: ListingPropertiesModel;
    readonly listingStatusTimer: ListingStatusTimerModel;
    readonly isAutoEnrichmentEnabled: boolean;
    readonly onAutoEnrichmentSwitchChange: (value: boolean) => void;
    readonly setIsGenerateContactRequestLinkModalOpen: (boolean: boolean) => void;
    readonly isEmptyParariusOfficeListing: boolean;
    readonly officeId: string | null;
}

export default function ListingDetailsHeader(props: ListingDetailsHeaderProps) {
    const {t} = useTranslation();
    const {isLoading} = useSelector((state: { ui: { isLoading: boolean } }) => state.ui);
    const statusTimerModel = props.listingStatusTimer.asBadge();

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    function onGenerateContactRequestLink() {
        props.setIsGenerateContactRequestLinkModalOpen(true);
    }

    function onToParariusOfficeButtonClick() {
        if (!props.officeId) {
            return;
        }
        const url = `https://crm.parariusoffice.nl/dossiers/${props.officeId}/property`;
        window.open(url, '_blank', 'noreferrer');
    }

    return <header className={styles.root}>
        {props.isEmptyParariusOfficeListing && !isLoading &&
            <div className={styles.infoContainer}>
                <HighlightBlock type={'info'}>{t('listing.details.parariusOfficeListing.empty.highlight')}</HighlightBlock>
            </div>
        }
        <div className={styles.mainContainer}>
            <div className={styles.addressContainer}>
                {!isLoading ? <>
                    <h1 className={styles.addressHeader}>
                        {props.addressModel.getAddressWithoutPostalCode()}
                    </h1>
                    <p className={styles.postalCode}>
                        {props.addressModel.postalCode}
                    </p>
                </> : <>
                    <div className={styles.addressHeader}>
                        <SkeletonComponent width={300}/>
                    </div>
                    <SkeletonComponent width={150}/>
                </>}

                <ListingProperties model={props.listingProperties}/>
                {statusTimerModel &&
                    <div data-testid="timer-badge">
                        <Badge_Deprecated
                            model={statusTimerModel}
                        />
                    </div>
                }
            </div>
            <div className={styles.switchContainer}>
                <div className={styles.contactFormButtonGroup}>
                    <Button
                        size={ButtonSize.Md}
                        onClick={onGenerateContactRequestLink}
                        buttonType={ButtonType.SecondaryGray}
                        icon={'ri-user-add-line'}
                        iconType={IconType.Leading}
                        title={t('listing.details.generateContactRequestLinkModalButton.title')}
                    ></Button>
                    {props.officeId && !isLoading &&
                        <Button
                            size={ButtonSize.Md}
                            onClick={onToParariusOfficeButtonClick}
                            buttonType={ButtonType.SecondaryGray}
                            icon={'ri-external-link-line'}
                            iconType={IconType.Leading}
                            title={t('listing.details.parariusOfficeListing.openInParariusOfficeButton.title')}
                        ></Button>
                    }
                </div>
                <Switch.Group as="div" className={styles.switchGroup}>
                    <Switch
                        checked={props.isAutoEnrichmentEnabled}
                        onChange={(val) => {
                            props.onAutoEnrichmentSwitchChange(val);
                        }}
                        disabled={props.listingStatusTimer.isListingClosed()}
                        className={classNames(
                            styles.switchBody,
                            props.isAutoEnrichmentEnabled ? styles.on : styles.off,
                            props.listingStatusTimer.isListingClosed() ? styles.disabled : ''
                        )}
                    >
                        <span
                            className={classNames(
                                styles.switchDot,
                                props.isAutoEnrichmentEnabled ? styles.on : styles.off,
                                props.listingStatusTimer.isListingClosed() ? styles.off : ''
                            )}
                        />
                    </Switch>
                    {props.listingStatusTimer.isListingClosed() && listingStatus.Closed ?
                        <>
                            <Tooltip text={t('listing.details.requestAutoInfo.tooltip.disabled')}
                                size={maxWidthEnum.Md}>
                                <div className={styles.switchTextDisabled}>
                                    <Text size={fontSizeEnum.Md} color={colorEnum.Gray400}>
                                        <Switch.Label
                                            as="span"
                                            className={styles.switchLabel}
                                            passive
                                        >
                                            {t('listing.details.requestAutoInfo')}
                                        </Switch.Label>
                                    </Text>
                                </div>
                            </Tooltip>
                            <div className={styles.icon}>
                                <i className={`${styles.disabled} ri-information-line`}></i>
                            </div>
                        </> : <>
                            <div className={styles.switchText}>
                                <Text size={fontSizeEnum.Md} color={colorEnum.Gray800} weight={fontWeightEnum.Medium}>
                                    <Switch.Label
                                        as="span"
                                        className={styles.switchLabel}
                                        passive
                                    >
                                        {t('listing.details.requestAutoInfo')}
                                    </Switch.Label>
                                </Text>
                            </div>
                            <div className={styles.icon}>
                                <Tooltip text={t('listing.details.requestAutoInfo.tooltip')} size={maxWidthEnum.Md}>
                                    <i className="ri-information-line"></i>
                                </Tooltip>
                            </div>
                        </>
                    }
                </Switch.Group>
            </div>
        </div>
    </header>
}
