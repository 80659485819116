import { useLocation, useParams } from 'react-router-dom';
import { routeByPathname, routePath } from '../../../routes';
import ButtonNew from '../../molecules/button/Button';
import styles from './ScreeningNavigation.module.css';
import screeningSteps from './screeningSteps';
import SkeletonComponent from '../../../components/SkeletonComponent';
import LoadingSpinner from '../../atoms/loading-spinner';
import { useTranslation } from 'react-i18next';
import CheckCircleLineIcon from '../../atoms/icons/CheckCircleLineIcon';
import { classNames } from '../../utils';

interface ScreeningNavigationProps {
    isSubmitting?: boolean;
    isSuccess?: boolean;
}

const ScreeningNavigation = ({
    isSubmitting = false,
    isSuccess = false,
}: ScreeningNavigationProps) => {
    const { t } = useTranslation();
    const location = useLocation();
    const { verificationRequestAuthorisationId } = useParams();

    const currentRoute = routeByPathname(location.pathname);
    const currentStep = currentRoute ? screeningSteps[currentRoute.name] : null;

    console.log(routePath('ScreeningHousehold', { verificationRequestAuthorisationId }));
    console.log(currentRoute);

    if (currentStep === null) {
        return (
            <SkeletonComponent height={56} />
        )
    }

    return (
        <div className={styles.ScreeningNavigation}>
            <div className={classNames(styles.loadingContainer, isSubmitting && styles.isSubmitting, isSuccess && styles.isSuccess)}>
                {isSubmitting &&
                    <div className={styles.loadingWrapper}>
                        <div className={classNames(styles.loadingItem, styles.savingSuccessText)}>
                            <CheckCircleLineIcon className={styles.loadingIcon} /> {t('screening.saving.success')}
                        </div>
                        <div className={classNames(styles.loadingItem, styles.savingText)}>
                            <LoadingSpinner className={styles.loadingIcon} /> {t('screening.saving')}
                        </div>
                    </div>
                }
            </div>
            <ButtonNew
                type="submit"
                disabled={isSubmitting}
                testName="submit"
            >{t(currentStep.submitTranslationKey)}</ButtonNew>
        </div>
    );
}

export default ScreeningNavigation;
