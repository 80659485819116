import {ReactNode} from 'react';
import styles from './FeaturedIconWithText.module.css';
import {ColorEnum, FontSizeEnum, FontWeightEnum} from '../../enums';
import Text from '../../atoms/typography/Text';

interface FeaturedIconWithTextProps {
    icon: ReactNode;
    text: string;
}

const FeaturedIconWithText = ({
    icon,
    text,
}: FeaturedIconWithTextProps) => {
    return (
        <div className={styles.feedbackContainer}>
            <div className={styles.iconContainer}>
                {icon}
            </div>
            <Text
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray800}
                weight={FontWeightEnum.Regular}
            >{text}</Text>
        </div>
    );
};

export default FeaturedIconWithText;
