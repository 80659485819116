/* eslint-disable-next-line */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import Quill from 'quill';

const Block: any = Quill.import('blots/block');

class HeadingBlot extends Block {
    static blotName = 'custom-header';
    static tagName = ['h1', 'h2', 'h3'];
}

export default HeadingBlot;
