import React, {ReactNode, useEffect, useRef, useState} from 'react';
import styles from './ProfileDropdownMenu.module.css';
import useAuth from '../../../hooks/useAuth';
import {t} from 'i18next';
import {Dropdown} from '../dropdown';
import {classNames} from '../../utils';
import SettingsLineIcon from '../../atoms/icons/SettingsLineIcon';
import LogoutBoxRIcon from '../../atoms/icons/LogoutBoxRIcon';
import {ColorEnum, FontSizeEnum, FontWeightEnum} from '../../enums';
import Text from '../../atoms/typography/Text';
import {useNavigate} from 'react-router-dom';
import ButtonNew from '../../molecules/button/Button';

interface ProfileDropdownMenuProps {
    isNavigationDisabled?: boolean;
}

type MenuItem = {
    icon: ReactNode,
    text: string,
    action: () => void,
};

type MenuItems = {
    [key: string]: MenuItem,
};

const ProfileDropdownMenu = ({ isNavigationDisabled = false }: ProfileDropdownMenuProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const {user, logout} = useAuth();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const [menuItems, setMenuItems] = useState<MenuItems>({});

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('pointerdown', handleClickAway);
        }

        return () => {
            document.removeEventListener('pointerdown', handleClickAway);
        }
    }, [isMenuOpen]);

    const handleClickAway = (event: PointerEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            handleClick();
        }
    };

    const handleSettingsClick = () => {
        navigate('/instellingen');
    }

    const handleLogoutClick = () => {
        logout({
            returnTo: window.location.origin
        });
    }

    const constructMenuObject = () => {
        const newMenuItems: MenuItems = {};

        if (!isNavigationDisabled) {
            newMenuItems.settings = {
                icon: <SettingsLineIcon />,
                text: t('profileDropdownMenu.menuItem.settings'),
                action: handleSettingsClick,
            };
        }

        newMenuItems.logout = {
            icon: <LogoutBoxRIcon />,
            text: t('profileDropdownMenu.menuItem.logout'),
            action: handleLogoutClick,
        };

        return newMenuItems;
    }

    useEffect(() => {
        setMenuItems(constructMenuObject());
    }, [isNavigationDisabled]);

    const handleClick = () => {
        setIsMenuOpen(!isMenuOpen);
    }

    const renderProfileImage = () => {
        const username = user?.nickname || user?.name || user?.email || '';

        return username.toUpperCase().slice(0, 1);
    }

    const renderMenuItem = (key: string, item: MenuItem) => {
        return (
            <div key={key} className={styles.menuItemContainer}>
                <ButtonNew variant={'menu-item'} iconBefore={item.icon} onClick={item.action}>
                    {item.text}
                </ButtonNew>
            </div>
        )
    }

    return (
        <div className={styles.container} ref={ref}>
            <button className={classNames(styles.profileButton, isMenuOpen && styles.profileButtonActive)} onClick={handleClick}>
                {renderProfileImage()}
            </button>
            <Dropdown
                isActive={isMenuOpen}
                align="right"
            >
                {user?.email &&
                    <div className={styles.menuHeaderContainer}>
                        <div className={styles.menuHeaderSubContainer}>
                            <div className={styles.profileImageContainer}>
                                <div className={styles.profileImage}>
                                    {renderProfileImage()}
                                </div>
                            </div>
                            <div className={styles.menuHeaderTextContainer}>
                                <Text size={FontSizeEnum.Sm} weight={FontWeightEnum.Regular} color={ColorEnum.Gray700}>
                                    {`${t('profileDropdownMenu.menuHeader.loggedInUser')}`}
                                </Text>
                                <Text  size={FontSizeEnum.Sm} color={ColorEnum.Gray500}>
                                    {user.email}
                                </Text>
                            </div>
                        </div>
                    </div>
                }
                {Object.entries(menuItems).map((i) => {
                    return renderMenuItem(i[0], i[1]);
                })}
            </Dropdown>
        </div>
    );
};

export default ProfileDropdownMenu;
