import CandidateViewingStatus from '../../enums/candidateViewingStatus';

const initialState = {
    data: {
        viewingInvitation: {
            viewing: {
                listing: {
                    agent: {
                        '@id': '',
                        '@type': '',
                        createdAt: '',
                        id: '',
                        logins: [],
                        loginsUris: [],
                        loginsPopulated: [],
                        name: '',
                        parariusOfficeKey: '',
                        phone: '',
                        logo: '',
                        city: '',
                        url: '',
                        postalCode: '',
                        house: '',
                        street: '',
                        defaultEmail: '',
                        defaultPhone: '',
                        emailsForInquiries: [],
                        leadsAgentIds: [],
                        showHouseNumber: false,
                        privacyPolicy: '',
                        isEmailSecure: false,
                    },
                    city: '',
                    houseNumber: '',
                    link: '',
                    originName: '',
                    street: '',
                    id: ''
                }
            },
            candidate: {
                viewingInvitationStatus: CandidateViewingStatus.Pending,
            },
            isSubmitted: false
        }
    },
    error: null,
    isLoading: false,
};

export default function (state = initialState, action = {}) {
    const {payload, type} = action;
    switch (type) {
        case CandidateViewingStatus.Accepted:
            return {
                ...state,
                data: {
                    ...state.data,
                    viewingInvitation: {
                        ...state.data.viewingInvitation,
                        candidate: {
                            ...state.data.viewingInvitation.candidate,
                            viewingInvitationStatus: CandidateViewingStatus.Accepted
                        },
                        isSubmitted: true
                    }
                },
            };
        case CandidateViewingStatus.Declined:
            return {
                ...state,
                data: {
                    ...state.data,
                    viewingInvitation: {
                        ...state.data.viewingInvitation,
                        candidate: {
                            ...state.data.viewingInvitation.candidate,
                            viewingInvitationStatus: CandidateViewingStatus.Rejected
                        },
                        isSubmitted: true
                    }
                }
            };
        case 'CANDIDATE_LISTING_LOADED':
            return {
                ...state,
                data: {
                    ...state.data,
                    viewingInvitation: {
                        ...state.data.viewingInvitation,
                        viewing: {
                            ...state.data.viewingInvitation.viewing,
                            listing: {
                                agent: payload.agent,
                                id: payload.id,
                                link: payload.link,
                                origin_name: payload.originName,
                                street: payload.street,
                                show_house_number: payload.showHouseNumber,
                                house_number: payload.houseNumber,
                                image_url: payload.imageUrl,
                                postal_code: payload.postalCode,
                                city: payload.city,
                                price: payload.price,
                                surface: payload.surface,
                                rooms: payload.rooms,
                            },
                        }
                    }
                }
            };
        default:
            return state;
    }
}
