
const initialState = {
    data: {
        totalItems: 0,
        listings: []
    },
    selectedListing: {
        candidates: {
            totalItems: 0,
            items: []
        },
        image: '',
        description: '',
        city: '',
        houseNumber: '',
        price: '',
        street: '',
        enrichment: 'manual',
        address: '',
        listingStatus: '',
        surface: '',
        rooms: '',
        availabilityInDays: 0,
        onlineSinceInDays: 0,
        numberOfLeads: 0,
        numberOfLeadsToAssess: 0,
        numberOfMessages: 0,
        numberOfNotSuitableLeads: 0,
        numberOfRejectedLeads: 0,
        numberOfSuitableLeads: 0,
        numberOfUnassessedLeads: 0,
        numberOfUnreadMessages: 0,
        hasTenant: false,
        status: '',
        closedAt: null,
        originName: '',
        officeId: null
    },
    error: null,
    isLoading: false,
    errorMessage: '',

};

export default function (state = initialState, action = {}) {
    const {payload, type} = action;
    switch (type) {
        case 'GET_LISTINGS':
            return {
                ...state, data: {
                    totalItems: payload.totalItems,
                    listings: payload.result
                }
            };
        case 'GET_LISTING_DETAILS': {
            return {
                ...state,
                selectedListing: {
                    ...state.selectedListing,
                    id: payload.id,
                    city: payload.city,
                    houseNumber: payload.houseNumber,
                    price: payload.price,
                    surface: payload.surface,
                    rooms: payload.rooms,
                    street: payload.street,
                    hasTenant: payload.hasTenant,
                    enrichment: payload.enrichment,
                    address: payload.address,
                    listingStatus: payload.status,
                    numberOfLeads: payload.numberOfLeads,
                    numberOfLeadsToAssess: payload.numberOfLeadsToAssess,
                    numberOfNotSuitableLeads: payload.numberOfNotSuitableLeads,
                    numberOfRejectedLeads: payload.numberOfRejectedLeads,
                    numberOfSuitableLeads: payload.numberOfSuitableLeads,
                    numberOfUnassessedLeads: payload.numberOfUnassessedLeads,
                    originName: payload.originName,
                    officeId: payload.officeId
                }
            };
        }
        case 'SET_CANDIDATE_COUNT_PER_SELECTED_LISTING':
            return {
                ...state, selectedListing: {
                    ...state.selectedListing,
                    candidates: {
                        items: payload['result'],
                        totalItems: payload['totalItems']
                    }
                }
            };
        case 'SET_AUTO_ENRICHING_MODE':
            return {
                ...state,
                selectedListing: {
                    ...state.selectedListing,
                    details: {
                        ...state.selectedListing.details,
                        enrichment: payload
                    }
                }
            };
        default:
            return state;
    }
}
