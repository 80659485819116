/* eslint-disable-next-line */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import Quill from 'quill';

const Inline: any = Quill.import('blots/inline');

class LinkBlot extends Inline {
    static blotName = 'custom-link';
    static tagName = 'A';
    static SANITIZED_URL = 'about:blank';
    static PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel', 'sms'];

    static create(value: string) {
        const node = super.create(value) as HTMLElement;
        node.setAttribute('href', this.sanitize(value));
        node.setAttribute('rel', 'noopener noreferrer');
        node.setAttribute('target', '_blank');
        return node;
    }

    static formats(domNode: HTMLElement) {
        return domNode.getAttribute('href');
    }

    static sanitize(url: string) {
        return sanitize(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
    }

    format(name: string, value: unknown) {
        if (name !== this.statics.blotName || !value) {
            super.format(name, value);
        } else {
            this.domNode.setAttribute('href', (this.constructor as typeof LinkBlot).sanitize(value as string));
        }
    }
}

function sanitize(url: string, protocols: string[]) {
    const anchor = document.createElement('a');
    anchor.href = url;
    const protocol = anchor.href.slice(0, anchor.href.indexOf(':'));
    return protocols.indexOf(protocol) > -1;
}

export { LinkBlot as default, sanitize };
