import api from '../../../utils/api';
import {setNewNotification} from '../ui/setNotification';

export const createAgentWithLogins = async (agentData, loginsToCreate) => {
    let response;
    try {
        response = await api.post('api/agency/create', agentData);
    } catch (error) {
        setNewNotification({message: 'Failed to create agent: ' + error, type: 'error'});
        return;
    }

    const data = await response.json();
    const agentId = data.id;
    await api.post(`api/agents/add`, {agentEmails: loginsToCreate, agencyId: agentId});
};
