import { useTranslation } from 'react-i18next';
import HighlightBlock from '../../../molecules/highlightBlock/HighlightBlock';
import { Card } from '../../../organisms/card';
import styles from './ScreeningCoTenantsContent.module.css';
import { InputField } from '../../../organisms/input-field';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../redux/actions/ui/setNotification';
import { useNavigate, useParams } from 'react-router-dom';
import findScreeningHouseholdComposition, { HouseholdComposition, MainHomeseeker } from '../../../../api/screening/findScreeningHouseholdComposition';
import SkeletonComponent from '../../../../components/SkeletonComponent';
import Label from '../../../atoms/label/Label';
import Text from '../../../atoms/typography/Text';
import { FontWeightEnum } from '../../../enums';
import ButtonNew from '../../../molecules/button/Button';
import Form, { Data, FormErrors } from '../../../molecules/form/Form';
import FormError from '../../../molecules/form/FormError';
import submitCoTenants, { CoTenantsPayload } from '../../../../api/screening/submitCoTenants';

const ScreeningCoTenantsContent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { verificationRequestAuthorisationId } = useParams();

    const [ isFetchingHouseholdComposition, setIsFetchingHouseholdComposition ] = useState<boolean>(true);
    const [ isSubmittingCoTenants, setIsSubmittingCoTenants ] = useState<boolean>(false);
    const [ householdComposition, setHouseholdComposition] = useState<HouseholdComposition>(null);
    const [ mainHomeseeker, setMainHomeseeker] = useState<MainHomeseeker>(null);

    const [ payload, setPayload ] = useState<CoTenantsPayload>({
        coTenants: [],
        screeningVerificationAuthorizationRequestId: verificationRequestAuthorisationId,
    });

    const [, setIsFormValid] = useState<boolean>(false);
    const [formData, setFormData] = useState<Data>({});
    const [formErrors, setFormErrors] = useState<FormErrors>({});

    useEffect(() => {
        fetchScreeningHouseholdComposition();
    }, []);

    useEffect(() => {
        for (let i = 0; i < payload.coTenants.length; i++) {
            payload.coTenants[i] = {
                name: formData[`fullName-${i}`]?.value,
                email: formData[`email-${i}`]?.value,
            }
        }
    }, [formData]);

    const fetchScreeningHouseholdComposition = async () => {
        try {
            const response = await findScreeningHouseholdComposition(verificationRequestAuthorisationId);
            setPayload((prev) => {
                return {
                    ...prev,
                    coTenants: Array(response.householdComposition.adults - 1).fill({
                        name: '',
                        email: ''
                    }),
                };
            });
            setHouseholdComposition(response.householdComposition);
            setMainHomeseeker(response.mainHomeseeker);
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsFetchingHouseholdComposition(false);
        }
    }

    const handleSubmit = async () => {
        setIsSubmittingCoTenants(true);

        try {
            await submitCoTenants(payload);

            navigate(`/screening/${verificationRequestAuthorisationId}/id-verification`);
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsSubmittingCoTenants(false);
        }
    }

    const handleFormDataChange = (data: Data, isValid: boolean, errors: FormErrors) => {
        setFormData(data);
        setIsFormValid(isValid);
        setFormErrors(errors);
    }

    const renderCoTenantsForms = (): ReactNode[] => {
        const coTenantsForms: ReactNode[] = [];

        for (let i = 0; i < householdComposition?.adults - 1; i++) {
            coTenantsForms.push(
                <Card
                    className={styles.gridMd}
                    elevated
                    key={i}
                >
                    <div>
                        <Text tag='h2' weight={FontWeightEnum.Bold}>{t('screening.coTenants.label.adult', { index: i+1 })}</Text>
                    </div>
                    <div>
                        <Label htmlFor={`fullName-${i}`} required>{t('screening.coTenants.label.fullName')}</Label>
                        <InputField
                            id={`fullName-${i}`}
                            name={`fullName-${i}`}
                            value={formData[`fullName-${i}`]?.value}
                            maxlength={255}
                            required
                        />
                        <FormError errors={formErrors[`fullName-${i}`]} />
                    </div>
                    <div>
                        <Label htmlFor={`email-${i}`} required>{t('screening.coTenants.label.emailAddress')}</Label>
                        <InputField
                            id={`email-${i}`}
                            name={`email-${i}`}
                            type='email'
                            value={formData[`email-${i}`]?.value}
                            maxlength={255}
                            required
                        />
                        <FormError errors={formErrors[`email-${i}`]} />
                    </div>
                </Card>
            );
        }

        return coTenantsForms;
    }

    return (
        <div>
            {(!isFetchingHouseholdComposition && householdComposition && mainHomeseeker) ?
                <>
                    <div className={styles.gridLg}>
                        <HighlightBlock type='info'>{t('screening.coTenants.infoBlock')}</HighlightBlock>
                        <Card
                            className={styles.gridMd}
                            elevated
                        >
                            <div>
                                <Text tag='h2' weight={FontWeightEnum.Bold}>{t('screening.coTenants.label.contactInto')}</Text>
                            </div>
                            <div>
                                <Label htmlFor='name' required>{t('screening.coTenants.label.fullName')}</Label>
                                <InputField
                                    name='name'
                                    value={mainHomeseeker?.name}
                                    disabled
                                    required
                                />
                            </div>
                            <div>
                                <Label htmlFor='name' required>{t('screening.coTenants.label.emailAddress')}</Label>
                                <InputField
                                    name='email'
                                    type='email'
                                    value={mainHomeseeker?.email}
                                    disabled
                                    required
                                />
                            </div>
                        </Card>
                        <Form
                            onChange={handleFormDataChange}
                            onSubmit={handleSubmit}
                        >
                            <div className={styles.gridLg}>
                                {renderCoTenantsForms()}
                                <div className={styles.submitButtonContainer}>
                                    <ButtonNew
                                        type='submit'
                                        loading={isSubmittingCoTenants}
                                        testName={'submit'}
                                    >{t('screening.coTenants.submit.button.next')}</ButtonNew>
                                </div>
                            </div>
                        </Form>
                    </div>
                </>
                :
                <>
                    <SkeletonComponent height={56}/>
                    <SkeletonComponent height={204}/>
                    <SkeletonComponent height={204}/>
                </>
            }
        </div>
    );
}

export default ScreeningCoTenantsContent;
