import api, { BodyData } from '../../utils/api';

interface CoTenant extends BodyData {
    name: string;
    email: string;
}
export interface CoTenantsPayload extends BodyData {
    screeningVerificationAuthorizationRequestId: string;
    coTenants: CoTenant[];
}

const submitCoTenants = async (data: CoTenantsPayload): Promise<unknown> => {
    return await api.postWithoutAuth(`api/screening/verification-request/co-tenants/save`, data);
}

export default submitCoTenants;
