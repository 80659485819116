import { request } from '../../../utils/api';
import { UI_ACTIONS } from '../../../utils/constants';


export const setCandidateDetails = (token, id, data, languageCode) => dispatch => {
    dispatch({ type: UI_ACTIONS.START_LOADING });
    let postData = {...data, locale: languageCode};
    request(token).post(`api/homeseeker/application/${id}/enrich`, postData)
        .then(() => {
            dispatch({ type: 'SHOW_FORM_SUCCESS' });
        })
        .catch((e) => {
            dispatch({ type: UI_ACTIONS.STOP_LOADING });
            console.log(e);
        })
        .finally(() => dispatch({ type: UI_ACTIONS.STOP_LOADING }));
};
