import api from '../../utils/api';

interface PetInfo {
    species: string;
    amount: number;
}

export interface HouseholdComposition {
    readonly adults: number;
    readonly children: number;
    readonly pets: PetInfo[]
}

export interface MainHomeseeker {
    readonly name: string;
    readonly email: string;
}

interface FindScreeningHouseholdCompositionResponse {
    readonly householdComposition: HouseholdComposition;
    readonly mainHomeseeker: MainHomeseeker;
}

const findScreeningHouseholdComposition = async (verificationRequestAuthorisationId: string): Promise<FindScreeningHouseholdCompositionResponse> => {
    return await api.getWithoutAuth(`api/screening/find-household-composition/${verificationRequestAuthorisationId}`);
}

export default findScreeningHouseholdComposition;
