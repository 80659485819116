import { ReactNode } from 'react';
import { Location, Pathname } from 'react-router-dom';
import AgentsOverview from './pages/admin/AgentsOverview';
import AgentDetails from './pages/admin/agents/AgentDetails';
import MergeDuplicateTransactionalListingsPage from './pages/admin/mergeDuplicateTransactionalListings/MergeDuplicateTransactionalListingsPage';
import DiscardedLeadsPage from './pages/admin/discardedLeads/DiscardedLeadsPage';
import ListingsOverview from './pages/dashboard/listings/ListingsOverview';
import ListingDetails from './pages/dashboard/listings/ListingDetails';
import SettingsPage from './pages/settings/SettingsPage';
import ViewingAttendancePage from './componentsNew/pages/viewingAttendancePage/ViewingAttendancePage';
import EnrichingFormPage from './pages/enriching-form/EnrichingFormPage';
import ContactRequestPage from './pages/contactRequestPage/ContactRequestPage';
import CookieDeclarationPage from './pages/cookies/CookieDeclarationPage';
import EmailAddressConfirmationPage from './pages/emailAddressConfirmation/EmailAddressConfirmationPage';
import ViewingInvitiationAcceptedPage from './componentsNew/pages/viewingInvitationAccepted/ViewingInvitiationAcceptedPage';
import ViewingInvitationDeclinedPage from './componentsNew/pages/viewingInvitationDeclined/ViewingInvitationDeclinedPage';
import ScheduleViewingPage from './componentsNew/pages/scheduleViewing/ScheduleViewingPage';
import LoginPage from './pages/login/LoginPage';
import LogoutPage from './pages/LogoutPage';
import SSOLoginPage from './pages/login/SSOLoginPage';
import MarketingMessagesSubscribePage from './pages/marketingMessagesSubscribe/MarketingMessagesSubscribePage';
import MarketingMessagesUnsubscribePage from './pages/marketingMessagesUnsubscribe/MarketingMessagesUnsubscribePage';
import ScreeningPage from './componentsNew/pages/screening/ScreeningPage';
import ScreeningIntroductionContent from './componentsNew/pages/screening/pageContent/ScreeningIntroductionContent';
import ScreeningHouseholdContent from './componentsNew/pages/screening/pageContent/ScreeningHouseholdContent';
import ScreeningCoTenantsContent from './componentsNew/pages/screening/pageContent/ScreeningCoTenantsContent';
import ParariusOfficeListingIdRedirect from './componentsNew/pages/listingDetail/ParariusOfficeListingIdRedirect';

export interface Route {
    path: string;
    name?: string;
    component: () => ReactNode;
    childRoutes?: Route[];
}
export interface Routes {
    authenticatedRoutes: Route[];
    guestRoutes: Route[];
    unauthenticatedRoutes: Route[];
}

const routes: Routes = {
    authenticatedRoutes: [
        {path: '/admin', component: AgentsOverview},
        {path: '/admin/agents/:id', component: AgentDetails},
        {path: '/admin/dubbele-advertenties', component: MergeDuplicateTransactionalListingsPage},
        {path: '/admin/onverwerkbare-leads', component: DiscardedLeadsPage},
        {path: '/admin/onverwerkbare-leads/:agentId', component: DiscardedLeadsPage},
        {path: '/dashboard/advertenties', name: 'ListingOverview', component: ListingsOverview},
        {path: '/dashboard/advertenties/:id', name: 'ListingDetails', component: ListingDetails},
        {path: '/dashboard/advertenties/:id/:status', component: ListingDetails},
        {path: '/dashboard/listings', component: ListingsOverview},
        {path: '/dashboard/listings/:id', component: ListingDetails},
        {path: '/instellingen', component: SettingsPage},
        {path: '/listing/:listingId/viewings/:viewingId/overview', component: ViewingAttendancePage},
        {path: '/listing/:listingId/viewings/:viewingId/overview/:candidateId', component: ViewingAttendancePage},
        {path: '/office-listing/:officeId', component: ParariusOfficeListingIdRedirect},
        {path: '/settings', component: SettingsPage},
        {path: '/logout', component: LogoutPage},
    ],
    guestRoutes: [
        {path: '/login', component: LoginPage},
        {path: '/sso-login', component: SSOLoginPage},
    ],
    unauthenticatedRoutes: [
        {path: '/applicant-form/:id', component: EnrichingFormPage},
        {path: '/applicant-form/:id/:languageCode', component: EnrichingFormPage},
        {path: '/contact-request/:id', component: ContactRequestPage},
        {path: '/contact-verzoek/:id', component: ContactRequestPage},
        {path: '/cookies', component: CookieDeclarationPage},
        {path: '/email-address-confirmation/:id', component: EmailAddressConfirmationPage},
        {path: '/email-address-confirmation/:id/:listingCity', component: EmailAddressConfirmationPage},
        {path: '/homeseeker/:homeseekerApplicationId/viewing/:viewingId/timeslot/accepted', component: ViewingInvitiationAcceptedPage},
        {path: '/homeseeker/:homeseekerApplicationId/viewing/:viewingId/timeslot/declined', component: ViewingInvitationDeclinedPage},
        {path: '/homeseeker/:homeseekerApplicationId/viewing/:viewingId/timeslot/schedule', component: ScheduleViewingPage},
        {path: '/subscribe-to-marketing-messages/:id', component: MarketingMessagesSubscribePage},
        {path: '/subscribe-to-marketing-messages/:id/:listingCity', component: MarketingMessagesSubscribePage},
        {path: '/unsubscribe-from-marketing-messages/:id', component: MarketingMessagesUnsubscribePage},
        {
            path: '/screening/:verificationRequestAuthorisationId',
            name: 'Screening',
            component: ScreeningPage,
            childRoutes: [
                {
                    path: '/screening/:verificationRequestAuthorisationId/introduction',
                    name: 'ScreeningIntroduction',
                    component: ScreeningIntroductionContent
                },
                {
                    path: '/screening/:verificationRequestAuthorisationId/household',
                    name: 'ScreeningHousehold',
                    component: ScreeningHouseholdContent
                },
                {
                    path: '/screening/:verificationRequestAuthorisationId/co-tenants',
                    name: 'ScreeningCoTenants',
                    component: ScreeningCoTenantsContent
                },
            ]
        },
    ]
};

export const findRouteByName = (name: string, thisRoutes: Route[] = []): Route | null => {
    if (thisRoutes.length === 0) {
        thisRoutes = [...routes.authenticatedRoutes, ...routes.guestRoutes, ...routes.unauthenticatedRoutes];
    }

    for (const route of thisRoutes) {
        if (route.name === name) return route;

        if (route.childRoutes) {
            const result = findRouteByName(name, route.childRoutes);
            if (result) return result;
        }
    }

    return null;
};

export const routePath = (
    name: string,
    params: Record<string, string | number> = {}
): string => {
    const route = findRouteByName(name);

    const result = Object.keys(params).reduce(
        (path, param) => path?.replace(`:${param}`, String(params[param])),
        route?.path
    );

    if (!result) {
        console.error('Route not found: ', name, ' with params: ', params, ' redirecting to catch all route.');
        return '';
    }

    return result;
};

export const isCurrentRoute = (location: Location, name: string): boolean => {
    const route = findRouteByName(name);

    if (!route) {
        return false;
    }

    const routeRegex = new RegExp(`^${route.path.replace(/:\w+/g, '[^/]+')}$`);

    return routeRegex.test(location.pathname);
};

export const routeByPathname = (pathname: Pathname, thisRoutes: Route[] = []): Route | null => {
    if (thisRoutes.length === 0) {
        thisRoutes = [...routes.authenticatedRoutes, ...routes.guestRoutes, ...routes.unauthenticatedRoutes];
    }

    for (const route of thisRoutes) {
        const routeRegex = new RegExp(`^${route.path.replace(/:\w+/g, '[^/]+')}$`);

        if (routeRegex.test(pathname)) {
            return route;
        }

        if (route.childRoutes) {
            const result = routeByPathname(pathname, route.childRoutes);
            if (result) return result;
        }
    }

    return null;
};

export function catchAllRedirectPath(isAuthenticated: boolean): string
{
    return isAuthenticated ? '/dashboard/advertenties' : '/login';
}

export default routes;
