import React, {forwardRef, ReactNode, useRef} from 'react';
import styles from './Dropdown.module.css';
import { classNames } from '../../utils';
import { Card } from '../card';

interface DropdownProps {
    readonly isActive?: boolean;
    readonly className?: string;
    readonly children: ReactNode
    readonly align?: 'left' | 'right';
    readonly fullWidth?: boolean;
    readonly unselectable?: boolean;
}

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(({
    isActive = false,
    className,
    children,
    align = 'left',
    fullWidth = false,
    unselectable = false,
}, ref) => {
    const dropdownCard = useRef<HTMLDivElement>(null);

    return (
        <div
            ref={ref}
            className={classNames(className && className, styles.Dropdown, isActive && styles.isActive, align && styles[align], fullWidth && styles.fullWidth)}
        >
            <Card
                ref={dropdownCard}
                className={styles.dropdownCard}
                hasPadding={false}
                elevated
                unselectable={unselectable}
            >
                {children}
            </Card>
        </div>
    );
});

Dropdown.displayName = 'Dropdown';

export default Dropdown;
