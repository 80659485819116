import styles from './PageContentNotFound.module.css';
import {Trans, useTranslation} from 'react-i18next';
import IconWithFlowyBackground from '../../../components/molecules/iconWithFlowyBackground/IconWithFlowyBackground';
import {ColorEnum, FontSizeEnum, FontWeightEnum} from '../../enums';
import Text from '../../atoms/typography/Text';

const PageContentNotFound = () => {
    const {t} = useTranslation()

    return (
        <div className={styles.root}>
            <IconWithFlowyBackground icon={'ri-emotion-sad-line'}/>
            <Text
                size={FontSizeEnum.DisplaySm}
                weight={FontWeightEnum.Bold}
                color={ColorEnum.Gray700}
            >
                {t('errorPage.general.title')}
            </Text>
            <Text
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray500}
            >
                <Trans
                    i18nKey="errorPage.general.support"
                    components={{ Link: <a /> }}
                />
            </Text>
        </div>
    )
}

export default PageContentNotFound;
