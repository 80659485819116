const replaceOlUlTags = (htmlString: string, swap = false): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    const copyFrom = swap ? 'ul' : 'ol';
    const copyTo = swap ? 'ol' : 'ul';

    const copyFromElements = doc.querySelectorAll(copyFrom);

    copyFromElements.forEach((copyFromElement) => {
        const liElements = copyFromElement.querySelectorAll('li');
        let bulletCount = 0;
        let orderedCount = 0;

        liElements.forEach((li) => {
            const dataList = li.getAttribute('data-list');
            if (dataList === 'bullet') {
                bulletCount++;
            } else if (dataList === 'ordered') {
                orderedCount++;
            }
        });

        if (!swap && bulletCount > orderedCount) {
            const copyToElement = document.createElement(copyTo);
            copyToElement.innerHTML = copyFromElement.innerHTML;
            copyFromElement.replaceWith(copyToElement);
        }
    });

    return doc.body.innerHTML;
};

export default replaceOlUlTags;
