import React, { CSSProperties } from 'react';
import { Card } from '../card';
import styles from './ListingCard.module.css';
import { ListingDetails } from '../listing-details';

interface Listing {
    readonly imageUrl: string;
    readonly street: string;
    readonly houseNumber: string;
    readonly postalCode: string;
    readonly city: string;
    readonly price: number;
    readonly surface: number;
    readonly rooms: number;
}

interface ListingCardProps {
    readonly listing?: Listing;
}

const ListingCard = ({
    listing
}: ListingCardProps) => {

    const listingImageStyleAttr: CSSProperties = { };

    if (listing.imageUrl !== '') {
        listingImageStyleAttr.backgroundImage = `url('${listing.imageUrl}')`;
    }

    return (
        <Card
            elevated
            hasPadding={false}
        >
            <div className={styles.ListingDetails}>
                <div
                    className={styles.image}
                    style={listingImageStyleAttr}
                ></div>
                <div style={{ padding: '24px' }}>
                    {listing &&
                        <ListingDetails
                            street={listing.street}
                            houseNumber={listing.houseNumber}
                            postalCode={listing.postalCode}
                            city={listing.city}
                            price={listing.price}
                            surface={listing.surface}
                            rooms={listing.rooms}
                        />
                    }
                </div>
            </div>
        </Card>
    );
};

export default ListingCard;
