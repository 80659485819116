import { useTranslation } from 'react-i18next';
import { Logo } from '../../atoms/logo';
import Text from '../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum } from '../../enums';
import styles from './PageFooter.module.css';

const PageFooter = () => {
    const { t } = useTranslation();

    return (
        <footer className={styles.PageFooter}>
            <Text
                color={ColorEnum.Gray500}
                size={FontSizeEnum.Xs}
            >{t('powered.by')}</Text>
            <Logo/>
        </footer>
    );
}

export default PageFooter;
