import { useTranslation } from 'react-i18next';
import { Select } from '../../organisms/select';
import { Item } from '../../organisms/select/Select';
import Quill from 'quill';
import { Delta } from 'quill/core';

interface VariablesSelectorProps {
    quill: Quill;
    buttonClassName?: string;
    variables: string[];
}

const VariablesSelector = ({
    quill,
    buttonClassName,
    variables,
}: VariablesSelectorProps) => {
    const { t } = useTranslation();

    const handleOnChange = (item: Item) => {
        const range = quill.getSelection();

        if (!range) {
            return;
        }

        const delta: Delta = quill.insertEmbed(
            range.index,
            'variables-dropdown',
            item.value,
            Quill.sources.USER
        );
        setTimeout(() => {
            quill.setSelection(delta.length());
        }, 1)
    }

    return (
        <Select
            buttonClassName={buttonClassName}
            items={variables.map((variable) => {
                return {
                    label: `{{${variable}}}`,
                    value: variable,
                }
            })}
            placeholder={ t('variables') }
            onChange={handleOnChange}
        />
    )
}

export default VariablesSelector;
