import { classNames } from '../../utils';
import Loader4LineIcon from '../icons/Loader4LineIcon';
import styles from './LoadingSpinner.module.css';

interface LoadingSpinnerProps {
    className?: string;
}

const LoadingSpinner = ({
    className
}: LoadingSpinnerProps) => {
    return (
        <Loader4LineIcon className={classNames(styles.LoadingSpinner, className && className)}/>
    )
}

export default LoadingSpinner;
