import styles from './ViewingAttendee.module.css';
import {Attendee} from '../../../api/viewing/findViewingAttendance';
import ButtonNew from '../../molecules/button/Button';
import ArrowRightSLineIcon from '../../atoms/icons/ArrowRightSLineIcon';
import IconWithText from '../../atoms/typography/IconWithText';
import PhoneLineIcon from '../../atoms/icons/PhoneLineIcon';
import PencilLineIcon from '../../atoms/icons/PencilLineIcon';
import Text from '../../atoms/typography/Text';
import {ColorEnum, FontSizeEnum, FontWeightEnum} from '../../enums';
import {t} from 'i18next';
import { useNavigate, useParams } from 'react-router-dom';
import CreateNoteModal from '../../../components/modals/CreateNoteModal';

interface ViewingAttendeeProps {
    attendee: Attendee;
}

const ViewingAttendee = ({
    attendee
}: ViewingAttendeeProps) => {
    const navigate = useNavigate();
    const { listingId, viewingId } = useParams();

    const handleViewingAttendeeNameClick = () => {
        navigate(`/listing/${listingId}/viewings/${viewingId}/overview/${attendee.homeseekerApplicationId}`);
    }

    return (
        <div className={styles.viewingAttendee}>
            <ButtonNew
                variant={'link-gray'}
                fullWidth
                spaceBetween
                iconAfter={<ArrowRightSLineIcon/>}
                onClick={handleViewingAttendeeNameClick}
            >
                <Text
                    size={FontSizeEnum.Lg}
                    weight={FontWeightEnum.Semibold}
                    color={ColorEnum.Gray800}
                >{`${attendee.salutation ? attendee.salutation + ' ' : ''}${attendee.fullName}`}</Text>
            </ButtonNew>
            <div>
                {attendee.phoneNumber ? (
                    <ButtonNew
                        variant={'link-underline-gray'}
                        iconBefore={<PhoneLineIcon />}
                    >{attendee.phoneNumber}</ButtonNew>
                ) : (
                    <IconWithText icon={<PhoneLineIcon />}>-</IconWithText>
                )}
            </div>
            <ButtonNew
                variant={'secondary-gray'}
                fullWidth
                iconBefore={<PencilLineIcon />}
                onClick={() => CreateNoteModal.open(attendee.homeseekerApplicationId)}
            >
                {`${t('candidate.notes.createNote')}`}
            </ButtonNew>
        </div>
    );
}

export default ViewingAttendee;
