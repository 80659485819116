import api, { BodyData } from '../../utils/api';

interface PetInfo extends BodyData {
    species: string;
    amount: number;
}
export interface submitScreeningHouseholdCompositionPayload extends BodyData {
    screeningVerificationAuthorisationRequestId: string;
    amountOfAdults: number;
    amountOfChildren: number;
    pets: PetInfo[];
}

const submitScreeningHouseholdCompositionRequest = async (data: submitScreeningHouseholdCompositionPayload): Promise<unknown> => {
    return await api.postWithoutAuth(`api/screening/verification-request/household-composition/save`, data);
}

export default submitScreeningHouseholdCompositionRequest;
