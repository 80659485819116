import api from '../../utils/api'

export interface Homeseeker {
    readonly name: string;
    readonly isMainTenant: boolean;
}

export interface Listing {
    readonly city: string;
    readonly houseNumber: string;
    readonly imageUrl: string;
    readonly postalCode: string;
    readonly price: number;
    readonly rooms: number;
    readonly showHouseNumber: boolean;
    readonly street: string;
    readonly surface: number;
}

export interface Agency {
    readonly name: string;
}

export interface Screening {
    readonly deadlineDate: string;
}

export interface FindScreeningIntroductionResponse {
    readonly agency: Agency;
    readonly homeseeker: Homeseeker;
    readonly listing: Listing;
    readonly screening: Screening;
}

const findScreeningIntroduction = async (verificationRequestAuthorisationId: string): Promise<FindScreeningIntroductionResponse> => {
    return await api.getWithoutAuth(`api/screening/find-screening-introduction/${verificationRequestAuthorisationId}`);
}

export default findScreeningIntroduction;
