import { useTranslation } from 'react-i18next';
import Divider from '../../atoms/divider';
import { PageContainer } from '../../atoms/page-container';
import Text from '../../atoms/typography/Text';
import { FontSizeEnum, FontWeightEnum } from '../../enums';
import { ListingDetails } from '../../organisms/listing-details';
import { Main } from '../../organisms/main';
import { TopHeader } from '../../organisms/top-header';
import { ViewingDetails } from '../../organisms/viewing-details';
import styles from './ViewingAttendancePage.module.css';
import React, { ReactNode, useEffect, useState } from 'react';
import findViewingAttendance, { Attendee, Timeslot, Viewing } from '../../../api/viewing/findViewingAttendance';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../redux/actions/ui/setNotification';
import findListingViewingsOverview, { FindListingViewingsOverviewResponse, Listing, Viewing as ListingViewing } from '../../../api/viewing/findListingViewingsOverview';
import SkeletonComponent from '../../../components/SkeletonComponent';
import { ViewingSelect } from '../../organisms/select';
import { ViewingTimeslotPanel } from '../../organisms/viewing-timeslot-panel';
import { InputField } from '../../organisms/input-field';
import SearchLineIcon from '../../atoms/icons/SearchLineIcon';
import { Feedback } from '../../atoms/feedback';
import UserLineIcon from '../../atoms/icons/UserLineIcon';
import { PageWithSidePanel } from '../../atoms/page-with-side-panel';
import CandidateDetailsSidePanel from '../../../components/organisms/candidateDetailsSidePanel/CandidateDetailsSidePanel';
import CandidateDetailsSidePanelModel from '../../../components/organisms/candidateDetailsSidePanel/models/candidateDetailsSidePanelModel';
import SendMessageModal from '../../../components/modals/SendMessageModal';
import CreateNoteModal from '../../../components/modals/CreateNoteModal';
import api from '../../../utils/api';
import ScreeningRequestJsonToModelConverter from '../../../converters/screeningRequestJsonToModelConverter';
import CandidateModel from '../../../models/candidate/candidateModel';
import findHomeSeekerApplicationById from '../../../api/homeseeker-application/findHomeSeekerApplicationById';
import findScreeningRequest from '../../../api/screening-request/findScreeningRequest';
import { SidePanel, SidePanelCustomEventType } from '../../organisms/side-panel';
import { FieldState } from '../../molecules/form/Form';

const ViewingAttendancePage = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    // TODO: replaced to HomeseekerApplicationSidePanel
    const navigate = useNavigate();

    const { listingId, viewingId, candidateId } = useParams();

    const [currentViewingId, setCurrentViewingId] = useState<string>(viewingId);
    const [isLoadingTimeslots, setIsLoadingTimeslots] = useState<boolean>(true);
    const [isLoadingListing, setIsLoadingListing] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [listing, setListing] = useState<Listing | null>(null);
    const [viewings, setViewings] = useState<FindListingViewingsOverviewResponse['viewings'] | null>(null);
    const [items, setItems] = useState<Array<string | ListingViewing> | null>(null);
    const [viewing, setViewing] = useState<Viewing | null>(null);
    const [timeslots, setTimeslots] = useState<Timeslot[] | null>(null);
    const [search, setSearch] = useState<string>('');

    // TODO: replaced to HomeseekerApplicationSidePanel
    const [selectedCandidateId, setSelectedCandidateId] = useState(null);

    // TODO: remove legacy code
    const [sidePanelModel, setSidePanelModel] = useState(CandidateDetailsSidePanelModel.createEmpty())
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [isCreateNoteModalOpen, setIsCreateNoteModalOpen] = useState(false);

    // TODO: replaced to HomeseekerApplicationSidePanel
    const updateQueryParamsOnSidePanelChange = (e: SidePanelCustomEventType) => {
        if (!e.detail.open) {
            navigate(`/listing/${listingId}/viewings/${viewingId}/overview`);
        }
    }

    // TODO: replaced to HomeseekerApplicationSidePanel
    useEffect(() => {
        fetchListingViewingsOverview();

        SidePanel.addListener(updateQueryParamsOnSidePanelChange);

        return () => {
            SidePanel.removeListener(updateQueryParamsOnSidePanelChange);
        }
    }, []);

    useEffect(() => {
        fetchViewingWithTimeslots();
    }, [currentViewingId]);

    // TODO: replaced to HomeseekerApplicationSidePanel
    useEffect(() => {
        if (candidateId) {
            setSelectedCandidateId(candidateId);
        }
        SidePanel.setOpen(!!candidateId);
    }, [candidateId]);

    useEffect(() => {
        if (isLoadingTimeslots === false && isLoadingListing === false) {
            setIsLoading(false);
        }
    }, [isLoadingTimeslots, isLoadingListing])

    useEffect(() => {
        navigate(`/listing/${listingId}/viewings/${viewingId}/overview`);
        setCurrentViewingId(viewingId);
    }, [viewingId])

    // TODO: remove legacy code
    useEffect(() => {
        fetchSidePanelData();
    }, [selectedCandidateId]);

    // TODO: remove legacy code
    async function fetchSidePanelData() {
        if (!selectedCandidateId) {
            return
        }

        try {
            setSidePanelModel(CandidateDetailsSidePanelModel.createEmpty())

            const homeseekerApplicationResponse = await findHomeSeekerApplicationById(selectedCandidateId);
            const candidateModel = CandidateModel.fromJson({...homeseekerApplicationResponse.result})
            let withCandidate = sidePanelModel.with({candidateModel: candidateModel})

            if (candidateModel.screeningRequestId) {
                const screeningRequestResponse = await findScreeningRequest(candidateModel.screeningRequestId)
                withCandidate = withCandidate.with({
                    screeningRequestModel: ScreeningRequestJsonToModelConverter.convert({...screeningRequestResponse.result})
                })
            }

            setSidePanelModel(withCandidate.with({isCandidateLoading: false}));
        } catch (error) {
            setSidePanelModel(sidePanelModel.with({isCandidateLoading: false}));
        }
    }

    const fetchListingViewingsOverview = async () => {
        try {
            const response = await findListingViewingsOverview(listingId, currentViewingId);
            setListing(response.listing);
            setViewings(response.viewings);

            const items: Array<string | ListingViewing> = [];
            for (const key in response.viewings) {
                items.push(key, ...response.viewings[key]);
            }
            setItems(items);
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsLoadingListing(false);
        }
    }

    const fetchViewingWithTimeslots = async () => {
        try {
            const response = await findViewingAttendance(currentViewingId);
            setViewing(response);
            setTimeslots(response.timeslots);
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsLoadingTimeslots(false);
        }
    }

    const areThereMultipleViewings = (): boolean => {
        if (viewings === null) {
            return false;
        }
        const viewingDays = Object.keys(viewings);

        return viewingDays.length > 1 || Object.values(viewings)[0].length > 1;
    }

    const renderViewingTimeslotPanels = ((): ReactNode[] => {
        const panels = [];

        for (let i = 0; i < timeslots?.length; i++) {
            if (timeslots[i] !== undefined) {
                panels.push(
                    <ViewingTimeslotPanel
                        maximumNumberOfInviteesPerTimeslot={
                            viewing.maximumInviteesPerTimeslot !== null ? viewing.maximumInviteesPerTimeslot : viewing.maximumNumberOfAttendees
                        }
                        timeslot={timeslots[i]}
                    />
                );
            }
        }

        return panels;
    })();

    const handleSearchTerm = (state: FieldState, matchSearch: (str1: string, str2: string) => void) => {
        setSearch(state.value);

        if (state.value === '') {
            setTimeslots(viewing.timeslots);
        }

        const filteredTimeslots: Timeslot[] = [];

        for (const key in viewing.timeslots) {
            const timeslot = viewing.timeslots[key];
            const attendees = (timeslot.attendees as Attendee[]).filter((attendee) => {
                return matchSearch(attendee.fullName, state.value);
            });

            if (attendees.length === 0) {
                continue;
            }

            filteredTimeslots[key] = {
                ...timeslot,
                attendees,
            };
        }

        viewing.timeslots.map((timeslot) => {
            const attendees = (timeslot.attendees as Attendee[]).filter((attendee) => {
                return matchSearch(attendee.fullName, state.value);
            });

            return {
                ...timeslot,
                attendees,
            }
        });

        setTimeslots(filteredTimeslots);
    }

    // TODO: remove legacy code
    const sendMessageToSelectedCandidates = (subject, message, candidates) => {
        const homeseekerApplicationIds = candidates.map((person) => person.id)

        api.post(`api/agency/mailing/send-manual-email`, {
            homeseekerApplicationIds,
            subject,
            message,
        })
            .then(() => {
                dispatch(setNotification({
                    id: Date.now(),
                    type: 'success',
                    message: homeseekerApplicationIds.length > 1 ? 'Berichten verzonden.' : 'Bericht verzonden.',
                }));
            })
            .catch((error) => {
                console.error(error);
                dispatch(setNotification({
                    id: Date.now(),
                    message: 'Er is iets fout gegaan',
                    type: 'failed',
                }))
            });
    }

    // TODO: remove legacy code
    function getSelectedCandidate() {
        const model = sidePanelModel.value.candidateModel
        return [model]
    }

    // TODO: remove legacy code
    async function sendHomeseekerApplicationNote(note, homeseekerApplicationId) {
        CreateNoteModal.close();
        setIsCreateNoteModalOpen(false)
        await api.post('api/homeseeker/application/note/create', {
            note: note,
            homeseekerApplicationId: homeseekerApplicationId,
        }).then(() => {
            dispatch(setNotification({
                id: Date.now(),
                message: t('candidate.notes.createNote.success.title'),
                type: 'success'
            }));
            fetchSidePanelData();
        }).catch(() => {
            dispatch(setNotification({
                id: Date.now(),
                message: t('candidate.notes.createNote.error.title'),
                type: 'failed'
            }));
        })
    }

    return (
        <PageWithSidePanel>
            <PageContainer>
                <TopHeader/>
                <Main>
                    <div className={styles.grid}>
                        {!isLoading ?
                            <ListingDetails
                                street={listing.street}
                                houseNumber={listing.houseNumber}
                                postalCode={listing.postalCode}
                                city={listing.city}
                                price={listing.price}
                                surface={listing.surface}
                                rooms={listing.rooms}
                            />
                            :
                            <>
                                <SkeletonComponent height={100} width={300}/>
                                <SkeletonComponent width={300}/>
                            </>
                        }
                        <Divider/>
                        <Text
                            tag="h2"
                            size={FontSizeEnum.Md}
                            weight={FontWeightEnum.Semibold}
                        >{t('candidate.viewing.title')}</Text>
                        {!isLoading ? (
                            areThereMultipleViewings() ? (
                                <ViewingSelect
                                    items={items}
                                    viewing={viewing}
                                />
                            ) : (
                                <ViewingDetails
                                    viewing={viewing}
                                />
                            )
                        ) : (
                            <SkeletonComponent height={48} width={300}/>
                        )}
                        <Divider/>
                        <InputField
                            name='searchBar'
                            value={search}
                            onChange={handleSearchTerm}
                            iconBefore={<SearchLineIcon/>}
                            isSearch
                        />
                        {!isLoading ? (renderViewingTimeslotPanels.length > 0 ?
                            renderViewingTimeslotPanels.map((p, index) => {
                                return (
                                    <div key={index}>{p}</div>
                                )
                            }) : (
                                <>
                                    <Feedback
                                        icon={<UserLineIcon />}
                                        text={t('feedback.text.no.homeseeker.applications.found.with.search')}
                                    />
                                </>
                            )) : (
                            <SkeletonComponent height={48} width={300}/>
                        )}
                    </div>
                </Main>
            </PageContainer>
            {/* <HomeseekerApplicationSidePanel/> */}
            <CandidateDetailsSidePanel
                model={sidePanelModel}
                onMessageSendButtonClick={() => setIsMessageModalOpen(true)}
                onNoteCreateButtonClick={() => setIsCreateNoteModalOpen(true)}
            />
            <SendMessageModal
                receiversEmail=""
                sendMessageToCandidate={(subject, message, candidates) => sendMessageToSelectedCandidates(subject, message, candidates)}
                isOpen={isMessageModalOpen}
                selectedPeople={getSelectedCandidate()}
                setIsSendMessageModelOpen={setIsMessageModalOpen}
            />
            <CreateNoteModal
                isOpen={isCreateNoteModalOpen}
                onCloseButtonClick={() => setIsCreateNoteModalOpen(false)}
                onSubmitButtonClick={(note, homeseekerApplicationId) => sendHomeseekerApplicationNote(note, homeseekerApplicationId)}
            />
        </PageWithSidePanel>
    )
}

export default ViewingAttendancePage;
