import api from '../../utils/api';

interface FindListingIdByOfficeIdResponse {
    listingId: string
}

const findListingIdByOfficeId = async (officeId: string): Promise<FindListingIdByOfficeIdResponse> => {
    return await api.get(`api/transactional-listing/office-lising-to-leadflow-listing/${officeId}`);
};

export default findListingIdByOfficeId;
