import DisabledStatusButton from './_disabledStatusButton';
import PropTypes from 'prop-types';
import CandidateStatus from '../../enums/candidateStatus';
import styles from './_statusButtonGroup.module.css'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

/**
 * @deprecated Part of old ButtonGroup. When working with ButtonGroup use components/buttonGroup/ButtonGroup instead of _archivedComponents/_buttonGroup!
 */
export default function StatusButtonGroup(props) {
    const { handleCandidateStatus, id, hidden, status } = props;

    return (
        <div className={styles.buttonContainer}>
            <div className="items-center justify-center inline-flex">
                {hidden ?
                    <DisabledStatusButton>
                        <i className="ri-thumb-up-line ri-xl"></i>
                    </DisabledStatusButton>
                    :
                    <button
                        disabled={hidden}
                        onClick={() => status !== CandidateStatus.Suitable ? handleCandidateStatus('promote', id) : null}
                        type="button"
                        className={classNames('h-12 w-12 inline-flex items-center justify-center rounded-full border',
                            status === CandidateStatus.Suitable ?
                                'shadow-sm border-green-200 text-primary bg-green-100 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-100'
                                :
                                'shadow-sm border-primary text-primary  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary'
                        )}>
                        <i className="ri-thumb-up-line ri-xl"></i>
                    </button>
                }
            </div>
            <div className="items-center justify-center inline-flex">
                {hidden ?
                    <DisabledStatusButton>
                        <i className="ri-pause-circle-line ri-xl"></i>
                    </DisabledStatusButton>
                    :
                    <button
                        disabled={hidden}
                        onClick={() => status !== CandidateStatus.NotSuitable ? handleCandidateStatus('demote', id) : null}
                        type="button"
                        className={classNames('h-12 w-12 inline-flex items-center justify-center rounded-full border',
                            status === CandidateStatus.NotSuitable ?
                                'border-yellow-300 text-primary bg-yellow-200 hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-100'
                                :
                                'border-primary text-primary  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary'
                        )}>
                        <i className="ri-pause-circle-line ri-xl"></i>
                    </button>
                }
            </div>
            <div className="items-center justify-center inline-flex">
                {hidden ?
                    <DisabledStatusButton>
                        <i className="ri-thumb-down-line ri-xl"></i>
                    </DisabledStatusButton>
                    :
                    <button
                        disabled={hidden}
                        onClick={() => status !== CandidateStatus.Rejected ? handleCandidateStatus('reject', id) : null}
                        type="button"
                        className={classNames('h-12 w-12 inline-flex items-center justify-center rounded-full border',
                            status === CandidateStatus.Rejected ?
                                'border-red-300 text-primary bg-red-200 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-100'
                                :
                                'border-primary text-primary  hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-300'
                        )}>
                        <i className="ri-thumb-down-line ri-xl"></i>
                    </button>
                }
            </div>
        </div>
    );
}


StatusButtonGroup.propTypes = {
    id: PropTypes.string.isRequired,
    handleCandidateStatus: PropTypes.func,
    status: PropTypes.string,
    hidden: PropTypes.bool,
};
