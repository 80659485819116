import { ColorEnum, FontWeightEnum } from '../../../enums';
import { Card } from '../../../organisms/card';
import styles from './ScreeningHouseholdContent.module.css';
import { useTranslation } from 'react-i18next';
import Text from '../../../atoms/typography/Text'
import HighlightBlock from '../../../molecules/highlightBlock/HighlightBlock';
import IncrementalNumberInput from '../../../molecules/incremental-number-input/IncrementalNumberInput';
import { RadioInput } from '../../../atoms/radio-input';
import React, { useEffect, useState } from 'react';
import ButtonNew from '../../../molecules/button/Button';
import AddLineIcon from '../../../atoms/icons/AddLineIcon';
import PetSelector from '../../../organisms/pet-selector/PetSelector';
import { setNotification } from '../../../../redux/actions/ui/setNotification';
import { useNavigate, useParams } from 'react-router-dom';
import submitScreeningHouseholdCompositionRequest, {
    submitScreeningHouseholdCompositionPayload
} from '../../../../api/screening/submitScreeningHouseholdComposition';
import { useDispatch } from 'react-redux';
import findScreeningHouseholdComposition, { HouseholdComposition } from '../../../../api/screening/findScreeningHouseholdComposition';
import SkeletonComponent from '../../../../components/SkeletonComponent';

const ScreeningHouseholdContent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [hasPets, setHasPets] = useState('false');
    const [ isFetchingHouseholdComposition, setIsFetchingHouseholdComposition ] = useState<boolean>(true);
    const [ , setHouseholdComposition ] = useState<HouseholdComposition>(null);
    const { verificationRequestAuthorisationId } = useParams();
    const [ isSubmittingHouseholdComposition, setIsSubmittingHouseholdComposition ] = useState<boolean>(false);

    const [formData, setFormData] = useState<submitScreeningHouseholdCompositionPayload>({
        screeningVerificationAuthorisationRequestId: verificationRequestAuthorisationId,
        amountOfAdults: 1,
        amountOfChildren: 0,
        pets: []
    });

    useEffect(() => {
        fetchScreeningHouseholdComposition();
    }, []);

    const handleAdultCountChange = (value: number) => {
        setFormData(prevPayload => ({
            ...prevPayload,
            amountOfAdults: value
        }));
    }

    const handleChildrenCountChange = (value: number) => {
        setFormData(prevPayload => ({
            ...prevPayload,
            amountOfChildren: value
        }));
    }

    const addRow = () => {
        if (formData.pets.length < 50) {
            setFormData(prevPayload => ({
                ...prevPayload,
                pets: [...prevPayload.pets, { species: 'cat', amount: 1 }]
            }));
        }
    };

    const handleHasPetsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHasPets(event.target.value);
        if (event.target.value === 'false') {
            setFormData({
                ...formData,
                pets: []
            });
        } else {
            setFormData({
                ...formData,
                pets: [{ species: 'cat', amount: 1 }]
            });
        }
    }

    const handlePetsChange = (index: number, species?: string, amount?: number) => {
        const updatedPets = [...formData.pets];

        if (species !== undefined) {
            updatedPets[index] = { species: species, amount: updatedPets[index].amount };
        }

        if (amount !== undefined) {
            updatedPets[index] = {species: updatedPets[index].species, amount: amount};
        }

        setFormData({
            ...formData,
            pets: updatedPets
        });
    }

    const handleDelete = (index: number) => {
        const updatedPets = [...formData.pets];

        updatedPets.splice(index, 1);

        setFormData({
            ...formData,
            pets: updatedPets
        });
    };

    const handleSubmitVerificationClick = async () => {
        setIsSubmittingHouseholdComposition(true);
        try {
            await submitScreeningHouseholdCompositionRequest(formData);
            navigate(`/screening/${verificationRequestAuthorisationId}/co-tenants`);
        } catch (error) {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsSubmittingHouseholdComposition(false);
        }
    }

    const fetchScreeningHouseholdComposition = async () => {
        try {
            setIsFetchingHouseholdComposition(true);

            const response = await findScreeningHouseholdComposition(verificationRequestAuthorisationId);
            const householdCompositionRef = response.householdComposition;

            setHouseholdComposition(householdCompositionRef);

            const pets = householdCompositionRef.pets.map((pet, index) => ({
                species: pet.species,
                amount: pet.amount,
                index
            }));

            setHasPets(householdCompositionRef.pets?.length > 0 ? 'true' : 'false');

            setFormData({
                ...formData,
                amountOfAdults: householdCompositionRef.adults ?? 1,
                amountOfChildren: householdCompositionRef.children ?? 0,
                pets
            });
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsFetchingHouseholdComposition(false);
        }
    }

    return (
        <div className={styles.contentContainer}>
            {(!isFetchingHouseholdComposition) ?
                <>
                    <div className={styles.gridLg}>
                        <HighlightBlock type='info'>{t('screening.household.info')}</HighlightBlock>
                        <Card elevated>
                            <div className={styles.gridMd}>
                                <Text color={ColorEnum.Gray800} html={t('screening.household.text')}></Text>
                                <div className={styles.humanCompositionContainer}>
                                    <div className={styles.gridSm}>
                                        <IncrementalNumberInput
                                            name='amountOfAdults'
                                            label={t('screening.household.adultCount.label')}
                                            min={1}
                                            max={50}
                                            value={formData.amountOfAdults }
                                            onChange={handleAdultCountChange}
                                        />
                                        <IncrementalNumberInput
                                            name='amountOfChildren'
                                            label={t('screening.household.childrenCount.label')}
                                            min={0}
                                            max={50}
                                            value={formData.amountOfChildren}
                                            onChange={handleChildrenCountChange}
                                        />
                                    </div>
                                </div>
                                <RadioInput
                                    name='hasPets'
                                    label={t('screening.household.hasPets.label')}
                                    value={hasPets}
                                    onChange={handleHasPetsChange}
                                    isBoolean
                                />
                                {hasPets === 'true' && (
                                    <div className={styles.gridMd}>
                                        <div>
                                            <Text
                                                color={ColorEnum.Gray800}
                                                weight={FontWeightEnum.Regular}
                                                html={t('screening.household.amountOfPets.text')}
                                            ></Text>
                                        </div>
                                        <div className={styles.petCompositionContainer}>
                                            <div className={styles.gridSm}>
                                                {formData.pets.map((pet, index) => (
                                                    <PetSelector
                                                        key={index}
                                                        index={index}
                                                        value={pet.species}
                                                        amount={pet.amount}
                                                        label={t('screening.household.amountOfPets.other.text')}
                                                        inputFieldPlaceholder={t('screening.household.listItem.other')}
                                                        maxLengthInputFieldValue={100}
                                                        minIncrementalNumberInputValue={1}
                                                        maxIncrementalNumberInputValue={50}
                                                        onChange={handlePetsChange}
                                                        isDeletable={formData.pets.length > 1}
                                                        onDelete={() => handleDelete(index)}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                        <div className={styles.inputGrid}>
                                            {formData.pets.length < 50 && (
                                                <ButtonNew
                                                    className={styles.buttonMargin}
                                                    variant="link-gray"
                                                    onClick={addRow}
                                                    iconBefore={<AddLineIcon/>}
                                                >Voeg een huisdier toe</ButtonNew>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Card>
                        <div className={styles.submitButtonContainer}>
                            <ButtonNew
                                loading={isSubmittingHouseholdComposition}
                                testName={'submit'}
                                onClick={handleSubmitVerificationClick}
                            >{t('screening.household.submit.button.text')}</ButtonNew>
                        </div>
                    </div>
                </>
                :
                <>
                    <SkeletonComponent height={56}/>
                    <SkeletonComponent height={204}/>
                    <SkeletonComponent height={204}/>
                </>
            }
        </div>
    );
}

export default ScreeningHouseholdContent;
