import api from '../../../utils/api';


export const getAgentDetails = (token, id, params) => (dispatch) => {
    api.get(`api/agency/${id}`, { params: params })
        .then((data) => {
            dispatch({
                type: 'SET_AGENT_DETAILS',
                payload: data.agency,
            });

            data.agents.forEach((agent) => {
                dispatch({
                    type: 'ADD_AGENT_LOGIN',
                    payload: agent,
                });
            });
        }).catch((e) => console.log(e));
};
