import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import LoadingSpinner from '../../atoms/loading-spinner';
import findListingIdByOfficeId from '../../../api/transactional-listing/findListingIdByOfficeId';
import { routePath } from '../../../routes';

const ParariusOfficeListingIdRedirect = () => {
    const { officeId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchListingIdByOfficeId();
    }, [officeId, navigate]);

    const fetchListingIdByOfficeId = async () => {
        try {
            const response = await findListingIdByOfficeId(officeId);
            navigate(routePath('ListingDetails', {id: response.listingId}));
        } catch {
            navigate(routePath('ListingOverview'));
        }
    }

    return (
        <LoadingSpinner/>
    );
}

export default ParariusOfficeListingIdRedirect
