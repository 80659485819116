import { useEffect, useState } from 'react';
import { Select } from '../../organisms/select';
import { Item } from '../../organisms/select/Select';
import ToolbarStyles from './Toolbar.module.css';
import stringToBoolean from '../../utils/stringToBoolean';
import { isBoolean } from '../../utils';
import { EmitterSource } from 'quill';
import { Delta } from 'quill/core';

const H1 = 1;
const H2 = 2;
const H3 = 3;
const p = false;

const basicFormatItems: Item[] = [{
    label: 'Heading 1',
    value: `${H1}`,
}, {
    label: 'Heading 2',
    value: `${H2}`,
}, {
    label: 'Heading 3',
    value: `${H3}`,
}, {
    label: 'Normal',
    value: `${p}`,
}];

interface HeadingSelectorProps {
    format: (name: string, value: unknown, source?: EmitterSource) => Delta | null,
    selectedHeading: false | string;
}

const HeadingSelector = ({
    format,
    selectedHeading
}: HeadingSelectorProps) => {
    const [selectedBasicFormatItem, setSelectedBasicFormatItem] = useState(selectedHeading);

    useEffect(() => {
        let heading = stringToBoolean(selectedBasicFormatItem);

        if (!isBoolean(heading)) {
            heading = parseInt(heading);
        }

        format('custom-header', heading);
    }, [selectedBasicFormatItem]);

    const getSelectedHeadingByCurrentFormats = (): string => {
        if (selectedHeading == 'h1') {
            return '1';
        }

        if (selectedHeading == 'h2') {
            return '2';
        }

        if (selectedHeading == 'h3') {
            return '3';
        }

        return 'false';
    }

    const handleBasicFormatItemsChange = (item: Item) => {
        setSelectedBasicFormatItem(item.value);
    }

    return (
        <>
            <Select
                buttonClassName={ToolbarStyles.toolbarSelect}
                items={basicFormatItems}
                selected={getSelectedHeadingByCurrentFormats()}
                onChange={handleBasicFormatItemsChange}
            />
        </>
    );
}

export default HeadingSelector;
