import React from 'react';
import styles from './RadioInput.module.css';
import {useTranslation} from 'react-i18next';
import Label from '../label/Label';

type Option = {
    label: string;
    value: string;
};

interface RadioInputBase {
    readonly name: string;
    readonly label: string;
    readonly value: string | null;
    readonly onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    readonly isBoolean?: boolean;
    readonly options?: Option[];
}

interface RadioInputDefault extends RadioInputBase {
    readonly isBoolean?: false;
    readonly options: Option[];
}

interface RadioInputIsBoolean extends RadioInputBase {
    readonly isBoolean: true;
    readonly options?: never;
}

type RadioInputProps = RadioInputDefault | RadioInputIsBoolean;

const RadioInput = ({
    name,
    label,
    value,
    onChange,
    isBoolean,
    options,
}: RadioInputProps) => {
    const { t } = useTranslation();

    const isRadioButtonChecked = (radioValue: string): boolean => {
        return radioValue === value;
    }

    const radioButton = (option: Option): JSX.Element => {
        return (
            <div className={styles.inputContainer}>
                <div className={styles.radioButtonContainer}>
                    <input
                        className={styles.radioButtonInput}
                        type="radio"
                        id={option.label}
                        name={name}
                        value={option.value}
                        checked={isRadioButtonChecked(option.value)}
                        onChange={onChange}
                    />
                </div>
                <Label htmlFor={option.label}>{option.label}</Label>
            </div>
        );
    }

    const renderRadioButtons = (): JSX.Element[] => {
        let radioButtons: JSX.Element[] = [];

        if (isBoolean === true) {
            const isBooleanOptions: JSX.Element[] = [
                radioButton({ label: t('radio.input.false.label'), value: 'false' }),
                radioButton({ label: t('radio.input.true.label'), value: 'true' }),
            ];
            radioButtons = radioButtons.concat(isBooleanOptions);
        } else if (options && Array.isArray(options)) {
            radioButtons = options.map((option: Option) => radioButton(option));
        }

        return radioButtons;
    }

    return (
        <div className={styles.container}>
            <div>
                <Label>{label}</Label>
            </div>
            {renderRadioButtons()}
        </div>
    );
};

export default RadioInput;
