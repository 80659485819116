import api from '../../../utils/api';
import {setNewNotification, TYPES} from '../ui/setNotification';
import {t} from 'i18next';
import store from '../../store';

export const approveHomeseekerApplications = async (ids) => {
    try {
        await api.post(`api/homeseeker/applications/approve`, {
            homeseekerApplicationIds: [...ids],
        });
    } catch(error) {
        setNewNotification({
            type: TYPES.FAILED,
            message: error.response.data.error || t('candidate.action.approve.failed'),
            id: Date.now()
        });
    }

    const message = ids.length > 1 ? t('candidate.action.approved.plural') : t('candidate.action.approved');
    setNewNotification({
        type: TYPES.SUCCESS,
        message: message,
        id: Date.now()
    });
}

export const moveHomeseekersToWaitingList = async (ids) => {
    try {
        await api.post(`api/homeseeker/applications/tranfer-to-waiting-list`, {
            homeseekerApplicationIds: [...ids],
        });
    } catch (e) {
        console.error(e);
        setNewNotification({
            type: TYPES.FAILED,
            message: 'Er is iets fout gegaan',
            id: Date.now()
        });

        return;
    }

    const message = ids.length > 1 ? 'Kandidaten status aangepast' : 'Kandidaat status aangepast' ;
    setNewNotification({
        type: TYPES.SUCCESS,
        message: message,
        id: Date.now()
    });
};

export const rejectHomeseekerApplications = async (ids, listingId, isNotified) => {
    store.dispatch({ type: 'START_LOADING' });
    try {
        await api.post(`api/agency/homeseeker-applications/reject`, {
            homeseekerApplicationIds: [...ids],
            transactionalListingId: listingId,
            notifyHomeseeker: isNotified
        });
    } catch (e) {
        console.error(e);
        store.dispatch({ type: 'STOP_LOADING' });
        setNewNotification({
            type: TYPES.FAILED,
            message: 'Er is iets fout gegaan',
            id: Date.now()
        });

        return;
    }

    const message = ids.length > 1 ? 'Kandidaten afgewezen' : 'Kandidaat afgewezen' ;
    setNewNotification({
        type: TYPES.SUCCESS,
        message: message,
        id: Date.now()
    });

    store.dispatch({ type: 'STOP_LOADING' });
}
