import { ReactNode } from 'react';
import styles from './HighlightBlock.module.css';
import classNames from '../../utils/classNames';

type HighLightBlockType = 'warning' | 'info';

interface Props {
    readonly type?: HighLightBlockType;
    readonly bottomMarginInPx?: number | null;
    readonly children: ReactNode;
}

const HighlightBlock = ({
    type,
    bottomMarginInPx = null,
    children
}: Props) => {
    return (
        <div
            className={classNames(styles.highlightBlock, type ? styles[type] : null)}
            style={{ marginBottom: bottomMarginInPx !== null ? bottomMarginInPx + 'px' : '0' }}
        >
            <div className={styles.icon}>
                <i className='ri-information-line' />
            </div>
            <div>
                {children}
            </div>
        </div>
    )
}

export default HighlightBlock
