import {useTranslation} from 'react-i18next';
import Button from '../../components/buttons/button/Button';
import ButtonType from '../../components/buttons/button/logic/models/buttonType';
import styles from './LoginPage.module.css';
import useAuth from '../../hooks/useAuth';
import LeadflowLogo from '../../components/molecules/leadflowLogo/LeadflowLogo';
import { Link } from 'react-router-dom';

export default function LoginPage() {

    const { t } = useTranslation()
    const { loginWithPopup } = useAuth()

    return (
        <>
            <div className="min-h-full h-full flex flex-col flex items-center justify-center bg-gray-100">


                <div className="flex flex-1 items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-md w-[400px] space-y-8 bg-white px-10 py-14 rounded-xl shadow">
                        <div className="py-4">
                            <div className="mx-auto h-auto w-[230px]">
                                <LeadflowLogo/>
                            </div>

                        </div>
                        <div className={styles.buttonContainer}>
                            <Button
                                buttonType={ButtonType.Primary}
                                title={t('loginPage.login')}
                                isFullWidth
                                onClick={() => loginWithPopup()}
                            />
                        </div>
                    </div>
                </div>
                <div className="p-2 text-xs underline text-gray-500">
                    <Link
                        to="/cookies"
                    >
                        {t('cookies')}
                    </Link>
                </div>
            </div>
        </>
    )
}
