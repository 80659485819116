import AddressModel from '../model/addressModel'
import api from '../../../../utils/api'
import TransactionalListingToAddressConverter, {Listing} from './transactionalListingToAddressConverter'

export default class MergeDuplicateTransactionalListingsService {

    public async getAddress(listingId: string): Promise<AddressModel> {
        const result: {listing: Listing} = await api.get(`api/transactional-listing/deduplication/${listingId}`);

        return TransactionalListingToAddressConverter.convert(result.listing);
    }

    public async mergeListings(primaryListingId: string, redundantListingId: string): Promise<void> {
        await api.post('api/merge-duplicate-transactional-listing', {
            redundantListingId: redundantListingId,
            primaryListingId: primaryListingId
        })
    }
}
