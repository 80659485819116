import React from 'react';
import styles from './Icon.module.css';
import { classNames } from '../../utils';

export interface IconProps {
    className?: string;
}

const ItalicIcon = ({ className }: IconProps) => {
    return (
        <div className={classNames(styles.icon, className ?? className)}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
            >
                <path d="M15 20H7V18H9.92661L12.0425 6H9V4H17V6H14.0734L11.9575 18H15V20Z"></path>
            </svg>
        </div>
    );
};

export default ItalicIcon;
