import api from '../../../../utils/api'
import DiscardedLeadJsonToModelConverter, {DiscardedLeadJson} from './discardedLeadJsonToModelConverter'
import DiscardedLeadModel from '../model/discardedLeadModel'

interface GetAgencyEndpointResponse {
    result: {
        name: string
    }
}

interface GetDiscardedLeadsEndpointResponse {
    result: DiscardedLeadJson[]
}

export default class DiscardedLeadsPageService {

    public async getAgencyName(agentId: string|undefined): Promise<string> {
        if (!agentId) {
            return null;
        }

        const res: GetAgencyEndpointResponse = await api.get(`api/get-agency/${agentId}`)

        return res.result.name
    }

    public async getDiscardedLeads(agentId: string|undefined, searchQuery: string): Promise<DiscardedLeadModel[]> {
        const params = {searchQuery};

        if (!agentId) {
            const result: GetDiscardedLeadsEndpointResponse = await api.postWithTemplate(`api/get-discarded-leads`, params);

            return result.result.map(item => DiscardedLeadJsonToModelConverter.convert(item));
        }

        const result: GetDiscardedLeadsEndpointResponse = await api.postWithTemplate(`api/get-discarded-leads/${agentId}`, params);

        return result.result.map(item => DiscardedLeadJsonToModelConverter.convert(item));
    }

    public async dismissDiscardedLead(leadId: string): Promise<void> {
        await api.post(`api/dismiss-discarded-lead/${leadId}`)
    }
}
